import { List, message, Spin } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import { userStore } from "store/userStore";
import "./NotificationItem.less";

const NotificationItem = observer(
  ({ item, onReadOK }: { item: any; onReadOK: () => void }) => {
    const [loading, setLoading] = useState(false);
    const readNoti = async () => {
      if (item.isRead) return;
      setLoading(true);
      // await notificationApi.read(item.id);
      message.success("Đánh dấu là đã đọc");
      setLoading(false);
      userStore.getProfile();
      onReadOK();
    };
    return (
      <Spin spinning={loading}>
        <List.Item
          className={`notify-listItem item-hover ${
            !item.isRead ? "unread" : ""
          }`}
          onClick={readNoti}
        >
          <List.Item.Meta title={item.title} description={item.content} />
        </List.Item>
      </Spin>
    );
  }
);

export default NotificationItem;
