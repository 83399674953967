import ProductPackageV2 from "components/ProductPackage/ProductPackageV2";
import { AdminLayout } from "layouts/AdminLayout";
import { Services } from "pages/services/Services";
import { TosPage } from "pages/tos/TosPage";
import React, { lazy } from "react";
import { FaClock, FaUserFriends } from "react-icons/fa";
import { RiSearch2Fill } from "react-icons/ri";
import { RouteObject } from "react-router-dom";
import { NotFoundPage } from "views/404/NotFoundPage";
import { CheckTrackingIdPage } from "views/CheckTrackingPage/CheckTrackingIdPage";
import { CheckTrackingIdPageNotLogin } from "views/CheckTrackingPage/CheckTrackingIdPageNotLogin";
import RegisterPage from "views/Login/RegisterPage";
import CreateOrderPage from "views/OrderPage/CreateOrderPage";
import ProfilePage from "views/Profile/ProfilePage";

const LoginPageNew = lazy(() => import("views/Login/LoginPage"));

export interface Route extends RouteObject {
  title?: string;
  children?: Route[];
  icon?: React.ReactNode;
  breadcrumb?: string;
  isHidden?: boolean;
  name?: string;
  aliasPath: string;
  alwayShow?: boolean;
}

export const adminRoutes: Route[] = [
  {
    title: "Tra cứu vận đơn",
    path: "/customer/order-tracking",
    aliasPath: "customer/order-tracking",
    name: "customer",
    icon: <RiSearch2Fill className="custom-icon" />,
    breadcrumb: "Tra cứu vận đơn",
    element: <CheckTrackingIdPage title="Tra cứu vận đơn" />,
  },
  {
    title: "Lịch sử đơn hàng",
    icon: (
      <FaClock style={{ fontSize: "15px", transform: `translateY(4px)` }} />
    ),
    path: "/order-history",
    aliasPath: "/order-history",
    name: "order-history",
    breadcrumb: "Lịch sử đơn hàng",
    children: [
      {
        title: "Shipping Receipt",
        icon: <FaClock className="custom-icon" />,
        path: "shipping-receipt",
        aliasPath: "shipping-receipt",
        name: "shipping-receipt",
        breadcrumb: "Shipping Receipt",
        element: <CreateOrderPage title="Shipping Receipt" />,
      },
      {
        title: "Tracking Online",
        icon: <FaClock className="custom-icon" />,
        path: "tracking-online",
        aliasPath: "tracking-online",
        name: "tracking-online",
        breadcrumb: "Tracking Online",
        element: <ProductPackageV2 title="Tracking Online" />,
      },
    ],
  },
  {
    title: "Tài khoản",
    icon: <FaUserFriends className="custom-icon fs-18" />,
    path: "/account",

    aliasPath: "/account",
    name: "account",
    breadcrumb: "Tài khoản",
    element: <ProfilePage title="Tài khoản" />,
  },
];

export const routes: Route[] = [
  {
    element: <AdminLayout />,
    children: adminRoutes,
    path: "/",
    aliasPath: "adminRoutes",
  },
  {
    title: "Tra cứu đơn hàng",
    path: "/order-tracking",
    aliasPath: "/order-tracking",
    name: "order-tracking",
    breadcrumb: "Tra cứu đơn hàng",
    element: (
      <CheckTrackingIdPageNotLogin title="NHA TRANG EXPRESS: Công ty vận chuyển hàng hóa Mỹ - Việt Nam: UY TÍN, NHANH CHÓNG, CHUYÊN NGHIỆP" />
    ),
  },
  {
    title: "TERMS OF SERVICE",
    path: "/tos",
    aliasPath: "/tos",
    name: "tos",
    breadcrumb: "TERMS OF SERVICE",
    element: <TosPage />,
  },
  {
    title: "SERVICES",
    path: "/services",
    aliasPath: "/services",
    name: "services",
    breadcrumb: "SERVICES",
    element: <Services />,
  },
  {
    path: "/login",
    aliasPath: "login",
    element: <LoginPageNew />,
  },
  {
    path: "/sign-up",
    aliasPath: "sign-up",
    element: <RegisterPage />,
  },
  {
    path: "*",
    aliasPath: "*",
    element: <NotFoundPage />,
  },
];
