import { AppContentView } from "@crema";
import AppFooter from "@crema/core/AppLayout/components/AppFooter";
import AppHeader from "@crema/core/AppLayout/Default/AppHeader";
import { useLayoutContext } from "@crema/utility/AppContextProvider/LayoutContextProvider";
import { isEmpty } from "@crema/utility/GlobalHelper";
import { Grid, Layout } from "antd";
import clsx from "clsx";
import { AppBreadcrumb } from "components/Breadcrumb/Breadcrumb";
import { AuthProvider } from "provider/AuthProvider";
import { useCallback, useEffect, useState } from "react";
import { FooterType } from "shared/constants/AppEnums";
import "./styles/AdminLayout.scss";

const { Header, Sider, Content } = Layout;

const { useBreakpoint } = Grid;

export const AdminLayout = () => {
  // const [collapsed, setCollapsed] = useState(false);
  const [siteLayoutMarginLeft, setSiteLayoutMarginLeft] = useState(200);
  const [selectedKey, setSelectedKey] = useState("1");
  const width = useBreakpoint();
  const [isCollapsed, setCollapsed] = useState(false);
  const { footer, footerType } = useLayoutContext();

  const onToggleSidebar = () => {
    setCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (!isEmpty(width)) {
      if (width.xl) {
        setCollapsed(false);
      } else {
        setCollapsed(true);
      }
    }
  }, [width]);

  const toggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isCollapsed) {
      setSiteLayoutMarginLeft(80);
    } else {
      setSiteLayoutMarginLeft(200);
    }
  }, [isCollapsed]);

  useEffect(() => {
    // setInterval(() => {
    //   setSelectedKey("2");
    // }, 3000);
  }, []);

  return (
    <AuthProvider>
      <Layout
        className={clsx("app-layout", {
          appMainFooter: footer && footerType === FooterType.FLUID,
          appMainFixedFooter: footer && footerType === FooterType.FIXED,
        })}
      >
        {/* <AppSidebar isCollapsed={isCollapsed} /> */}

        <Layout className="app-layout-main">
          <AppHeader onToggleSidebar={onToggleSidebar} />
          {/* <AppScrollbar className="main-scrollbar" scrollToTop={undefined}> */}

          {/* <AppBreadcrumb /> */}

          <AppContentView dataResult={undefined} />
          <AppFooter />
          {/* </AppScrollbar> */}
        </Layout>
      </Layout>
    </AuthProvider>
  );
};
