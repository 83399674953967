import { Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import FormItem from "antd/lib/form/FormItem";
import { useAddress } from "hooks/useAddress";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { AddressData, City, District, Ward } from "types/address";
import { Receiver } from "types/receiver";
import { Region } from "types/region";
const rules: Rule[] = [{ required: true }];

export interface AddressParam {
  parentCode?: string;
}

export interface IReceiverAddressSelect {
  getValues: () => void;
  setValue: (address: IAddress) => void;
}

export interface IAddress {
  district: District;
  city: City;
  ward: Ward;
  // address: string;
}

export const ReceiverAddressSelect = React.forwardRef(
  (
    {
      form,
      selectedReceiver,
      onChange,
    }: {
      form: FormInstance<any>;
      selectedReceiver?: Receiver;
      onChange: (data: AddressData | undefined) => void;
    },
    ref
  ) => {
    const [queryWard, setQueryWard] = useState<AddressParam>();
    const [queryDistrict, setQueryDistrict] = useState<AddressParam>();
    const [isRequireDistrict, setIsRequireDistrict] = useState<boolean>(false);

    useEffect(() => {
      if (selectedReceiver) {
        setQueryDistrict({ parentCode: selectedReceiver?.city?.code });
        setQueryWard({ parentCode: selectedReceiver?.district?.code });
      }
    }, [selectedReceiver]);

    useImperativeHandle(
      ref,
      () => {
        return {
          async getValues() {},
          setValue(data: Partial<IAddress>) {
            if (data.ward) {
              setWards([...wards, data.ward]);
            }
            // if (data.city) {
            //   updateCity([...cities, data.city]);
            // }
            if (data.district) {
              setDistricts([...districts, data.district]);
            }

            form.setFieldsValue({
              receiverCityId: data?.city?.id,
              receiverWardId: data?.ward?.id,
              receiverDistrictId: data?.district?.id,
            });
          },
        };
      },
      []
    );

    const {
      cities,
      districts,
      loading,
      wards,
      fetchCity,
      fetchDistrict,
      fetchWard,
      clearDistrict,
      clearWard,
      setWards,
      setDistricts,
      setCities,
    } = useAddress();

    useEffect(() => {
      if (queryDistrict?.parentCode) {
        fetchDistrict(queryDistrict);
      }
    }, [queryDistrict]);

    useEffect(() => {
      if (queryWard?.parentCode) {
        fetchWard(queryWard);
      }
    }, [queryWard]);

    useEffect(() => {
      fetchCity();
    }, []);

    const handleChangeCity = (receiverCityId: number) => {
      form.resetFields(["receiverWardId", "receiverDistrictId"]);
      if (receiverCityId) {
        const find = cities.find((e) => e.id == receiverCityId);
        const code = find?.code;
        setQueryDistrict({ parentCode: code });
        setIsRequireDistrict(find?.region == Region.SaiGon);
      } else {
        clearDistrict();
        setIsRequireDistrict(false);
      }
    };

    const handleChangeDistrict = (receiverDistrictId: number) => {
      form.resetFields(["receiverWardId"]);
      if (receiverDistrictId) {
        const parentCode = districts.find(
          (e) => e.id == receiverDistrictId
        )?.code;
        setQueryWard({ parentCode });
      } else {
        clearWard();
      }
    };

    const handleSubmitAddress = (value: number) => {
      if (value) {
        const { receiverDistrictId, receiverCityId, receiverWardId } =
          form.getFieldsValue();
        const data: AddressData = {
          district: districts.find((e) => e.id == receiverDistrictId),
          ward: wards.find((e) => e.id == receiverWardId),
          city: cities.find((e) => e.id == receiverCityId),
        };
        onChange(data);
      } else {
        onChange(undefined);
      }
    };

    return (
      <>
        {/* <Form form={form} layout="vertical"> */}
        <FormItem
          rules={rules}
          required
          label="Địa chỉ"
          name={"receiverAddress"}
        >
          <Input />
        </FormItem>
        <FormItem
          rules={rules}
          required
          label="Tỉnh/Thành phố"
          name={"receiverCityId"}
        >
          <Select
            onChange={handleChangeCity}
            style={{ width: "100%" }}
            onClear={() => {
              clearDistrict();
              clearWard();
            }}
            allowClear
            placeholder="Nhập tên tỉnh/thành phố"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {cities?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          rules={isRequireDistrict ? rules : undefined}
          required={isRequireDistrict}
          label="Quận/Huyện"
          name={"receiverDistrictId"}
        >
          <Select
            disabled={!districts.length}
            onClear={clearWard}
            onChange={handleChangeDistrict}
            style={{ width: "100%" }}
            allowClear
            placeholder="Nhập tên Quận/Huyện"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {districts?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="Phường/Xã/Thị trấn" name={"receiverWardId"}>
          <Select
            disabled={!wards.length}
            style={{ width: "100%" }}
            allowClear
            onChange={handleSubmitAddress}
            placeholder="Nhập tên Phường/Xã/Thị trấn"
            showSearch
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {wards?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.nameWithType}
              </Select.Option>
            ))}
          </Select>
        </FormItem>

        {/* </Form> */}
      </>
    );
  }
);
