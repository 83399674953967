import { useEffect, useState } from "react";

export const useAppHeight = () => {
  const [appHeight, setAppHeight] = useState(0);

  useEffect(() => {
    // const appElement = document.querySelectorAll(".App");

    // setAppHeight(appElement?.[0].clientHeight);''
    setAppHeight(window.innerHeight);
  }, []);

  return { appHeight, sideBarHeight: appHeight - 260 };
};
