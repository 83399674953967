export enum Region {
  SaiGon = "SAIGON",
  Near = "NEAR", //Tỉnh gần
  Far = "FAR", //tỉnh xa
}

export const regionTrans = {
  [Region.SaiGon]: { color: "", title: "Sài Gòn", value: Region.SaiGon },
  [Region.Near]: { color: "", title: "Tỉnh gần", value: Region.Near },
  [Region.Far]: { color: "", title: "Tỉnh xa", value: Region.Far },
};
