import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { authApi } from "api/auth.api";
import { configApi } from "api/config.api";
import { useEffect, useState } from "react";
import { userStore } from "store/userStore";
import { getTitle } from "utils";

export const ProfilePage = ({ title }: { title: string }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [hotline, setHotline] = useState<string>();

  useEffect(() => {
    document.title = getTitle(title);
    // fetchHotline();
  }, []);

  //handle submit form
  const onFinish = async (values: any) => {
    const oldPassword = form.getFieldValue("oldPassword");
    const newPassword = form.getFieldValue("newPassword");
    setLoading(true);

    try {
      const res = await authApi.passwordUpdate({
        oldPassword,
        newPassword,
      });
      form.resetFields();
      message.success("Đã cập nhật mật khẩu của bạn!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="b-container" style={{ marginTop: "2em" }}>
      {" "}
      <Row gutter={16}>
        <Col
          className="gutter-row"
          xxl={10}
          xl={10}
          lg={10}
          md={24}
          style={{ margin: "auto", width: "100%" }}
        >
          <div className="box">
            <Card title="Đổi mật khẩu" bordered={false}>
              <Form
                form={form}
                onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  label="Mật khẩu cũ"
                  name="oldPassword"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền mật khẩu của bạn",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="Mật khẩu mới"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền mật khẩu mới của bạn",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="renewPassword"
                  label="Nhập lại mật khẩu"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập lại mật khẩu mới của bạn",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Mật khẩu không khớp"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="middle"
                  >
                    Cập nhật
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { ProfilePage as default };
