import { useEffect, useRef, useState } from "react";

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Segmented,
  Space,
} from "antd";

import { CopyFilled } from "@ant-design/icons";
import { AppRowContainer } from "@crema";
import AppAnimateGroup from "@crema/core/AppAnimateGroup";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import { Rule } from "antd/lib/form";
import { authApi } from "api/auth.api";
import { AddressSelect } from "components/AddressSelect/AddressSelect";
import { AddressModal } from "components/Map/components/AddressModal";
import {
  IReceiverAddressSelect,
  ReceiverAddressSelect,
} from "components/ReceiverAddressSelect/ReceiverAddressSelect";
import { SingleImageUpload } from "components/Upload/SingleImageUpload";
import { Gender } from "constant";
import md5 from "md5";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { AddressData } from "types/address";
import { Customer, CustomerAddressType } from "types/customer";
import { Receiver } from "types/receiver";
import { getTitle } from "utils";
import { getAddress } from "utils/address";
import "../../pages/userPages/index.style.less";
import { docSoBangChu } from "utils/docTienBangChu";
import { getUserName } from "utils/getUserName";
const hashKey = "DBZUYAgyxsdqgEboP";

const onFinishFailed = (errorInfo: any) => {};

const RegisterPage = () => {
  const addressValueRef = useRef<AddressData>();
  const receiverAddressRef = useRef<IReceiverAddressSelect>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<CustomerAddressType>(
    CustomerAddressType.USA
  );
  const navigation = useNavigate();
  const rules: Rule[] = [{ required: true }];
  const [visibleMap, setVisibleMap] = useState(false);
  const [isShowReceptionInput, setIsShowReceptionInput] = useState(false);
  const [result, setResult] = useState<{
    customer: Customer;
    receiver: Receiver;
  }>();
  const [visibleResult, setVisibleResult] = useState(false);
  const [hashUpload, setHashUpload] = useState<{
    hash: string;
    time: number;
  }>();

  const generateHash = () => {
    const time = moment().unix();
    const hash = md5(`${hashKey}.${time}`);

    setHashUpload({ hash, time });
  };

  const handleSubmit = async () => {
    await form.validateFields();
    setLoading(true);
    const {
      receiverAddress,
      receiverCityId,
      receiverDistrictId,
      receiverWardId,
      receiverName,
      receiverPhone,
      cityId,
      districtId,
      wardId,
      address,
      vnAddress,
      city,
      state,
      zipCode,
      street,
      ...customer
    } = form.getFieldsValue();

    customer.customerCityId = cityId;
    customer.customerDistrictId = districtId;
    customer.customerWardId = wardId;
    customer.addressType = country;
    customer.vnAddress = vnAddress;
    customer.city = city;
    customer.state = state;
    customer.zipCode = zipCode;
    customer.street = street;

    customer.address =
      country == CustomerAddressType.USA
        ? address
        : getAddress(
            addressValueRef.current?.ward,
            addressValueRef.current?.district,
            addressValueRef.current?.city,
            vnAddress
          );

    const receiver = {
      name: receiverName,
      phone: receiverPhone,
      address: receiverAddress,
      cityId: receiverCityId,
      districtId: receiverDistrictId,
      wardId: receiverWardId,
    };

    try {
      const { data } = await authApi.register({
        customer,
        receiver: receiver?.name ? receiver : undefined,
      });
      message.success("Đăng ký thành công, Vui lòng đăng nhập lại");
      setResult(data);
      setVisibleResult(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyAddress = async () => {
    try {
      await form.validateFields([
        "cityId",
        "districtId",
        "wardId",
        "vnAddress",
        "name",
        "phone",
      ]);

      const { vnAddress, cityId, districtId, wardId, name, phone } =
        form.getFieldsValue();

      if (addressValueRef.current) {
        const { ward, district, city } = addressValueRef.current;
        //@ts-ignore
        receiverAddressRef.current?.setValue({ ward, district, city });
        form.setFieldsValue({
          receiverAddress: vnAddress,
          receiverCityId: cityId,
          receiverDistrictId: districtId,
          receiverWardId: wardId,
          receiverName: name,
          receiverPhone: phone,
        });
      }
    } catch (error) {
      message.warning(
        "Vui lòng điền đầy đủ thông tin địa chỉ Việt Nam, tên, số điện thoại người gửi"
      );
    }
  };

  const resetReceiverAddressFields = () => {
    form.resetFields([
      "receiverAddress",
      "receiverCityId",
      "receiverDistrictId",
      "receiverWardId",
    ]);
  };

  const resetCustomerAddressFields = () => {
    form.resetFields(["vnAddress", "cityId", "districtId", "wardId"]);
  };

  useEffect(() => {
    generateHash();
    document.title = getTitle("Đăng ký");
  }, []);
  // const { messages } = useIntl();
  return (
    <div
      className="user-pages"
      style={{
        backgroundColor: "#f4f7fe",
        minHeight: "100vh",
        paddingTop: "3em",
      }}
    >
      <Form
        size="middle"
        layout="vertical"
        className="user-form"
        name="basic"
        initialValues={{ remember: true, gender: Gender.Male }}
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <AppAnimateGroup type="bottom">
          <AppPageMetadata title="Đăng ký" children={undefined} />
          <div className="user-container" key="a">
            <Card
              className="user-card user-card-lg"
              style={{
                borderRadius: "20px",
                textAlign: "left",
                maxWidth: 500,
              }}
            >
              <AppRowContainer>
                {/* <Col xs={24} md={12}>
                <div className="user-styled-img">
                  <Logo />
                </div>
              </Col> */}
                <Col span={24}>
                  <Link
                    to={"/"}
                    style={{
                      color: "#000",
                      width: "fit-content",
                      display: "block",
                    }}
                  >
                    <div className="brand" style={{ marginBottom: "2em" }}>
                      <Space direction={"horizontal"} size={"small"}>
                        <img
                          src={"/logo.png"}
                          alt=""
                          style={{ height: "60px" }}
                        />
                        <h1
                          style={{
                            marginBottom: "0.5em",
                            marginLeft: "1em",
                            textAlign: "left",
                            justifyContent: "start",
                          }}
                        >
                          Đăng ký tài khoản
                        </h1>
                        {/* <Space direction="vertical" align="start" size={0}>
                          <h2 style={{ marginBottom: 0 }}>
                            {process.env.REACT_APP_BRAND_NAME}
                          </h2>
                        </Space> */}
                      </Space>
                    </div>
                  </Link>{" "}
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Tên đăng nhập"
                    name="username"
                    className="form-field"
                    rules={[
                      { required: true },
                      {
                        pattern: /^[a-zA-Z0-9]+$/,
                        message:
                          "Sai định dạng (Không được chứa khoảng cách, ký tự đặt biệt, chữ có dấu)",
                      },
                      { max: 50, message: "Giới hạn 50 ký tự" },
                    ]}
                  >
                    <Input
                      placeholder={"Tên đăng nhập"}
                      autoComplete="new-username"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mật khẩu"
                    name="password"
                    className="form-field"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập mật khẩu",
                      },
                    ]}
                  >
                    <Input.Password type="password" placeholder={"Mật khẩu"} />
                  </Form.Item>
                  <Form.Item
                    label="Họ và tên"
                    name="name"
                    className="form-field"
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng nhập họ tên",
                      },
                    ]}
                  >
                    <Input placeholder={"Mật khẩu"} />
                  </Form.Item>
                  <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    className="form-field"
                    rules={rules}
                  >
                    <Input placeholder={""} autoComplete="" />
                  </Form.Item>
                  <Form.Item label="Email" name="email" className="form-field">
                    <Input placeholder={""} autoComplete="" />
                  </Form.Item>
                  <Row gutter={16}>
                    <Col span={12}>
                      {" "}
                      <Form.Item
                        shouldUpdate={true}
                        style={{ marginBottom: 0 }}
                      >
                        {() => {
                          return (
                            <Form.Item
                              label="Front of ID"
                              name="identityImageUrl"
                            >
                              <SingleImageUpload
                                onUploadOk={(path: string) => {
                                  form.setFieldsValue({
                                    identityImageUrl: path,
                                  });
                                }}
                                imageUrl={form.getFieldValue(
                                  "identityImageUrl"
                                )}
                                uploadUrl={
                                  process.env.REACT_APP_API_URL +
                                  `/v1/customer/image/upload?hash=${hashUpload?.hash}&time=${hashUpload?.time}`
                                }
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Form.Item
                        shouldUpdate={true}
                        style={{ marginBottom: 0 }}
                      >
                        {() => {
                          return (
                            <Form.Item
                              label="Back of ID"
                              name="identityBackImageUrl"
                            >
                              <SingleImageUpload
                                onUploadOk={(path: string) => {
                                  form.setFieldsValue({
                                    identityBackImageUrl: path,
                                  });
                                }}
                                imageUrl={form.getFieldValue(
                                  "identityBackImageUrl"
                                )}
                                uploadUrl={
                                  process.env.REACT_APP_API_URL +
                                  `/v1/customer/image/upload?hash=${hashUpload?.hash}&time=${hashUpload?.time}`
                                }
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Divider
                  orientation="left"
                  orientationMargin={15}
                  style={{ fontSize: 18, color: "#3973b9" }}
                >
                  Địa chỉ (Người gửi)
                </Divider>
                <p style={{ textAlign: "center", width: "100%" }}>
                  <i>
                    Xin điền địa chỉ US <b>hoặc</b> địa chỉ Việt Nam của người
                    gửi
                  </i>
                </p>
                <Space style={{ width: "100%", justifyContent: "center" }}>
                  <Segmented
                    onChange={(value) => {
                      if (value == CustomerAddressType.VN) {
                        form.resetFields(["address"]);
                      }
                      if (value == CustomerAddressType.USA) {
                        resetCustomerAddressFields();
                      }
                      //@ts-ignore
                      setCountry(value);
                    }}
                    options={[
                      {
                        label: "US",
                        value: CustomerAddressType.USA,
                      },
                      {
                        label: "Việt Nam",
                        value: CustomerAddressType.VN,
                      },
                    ]}
                    value={country}
                  />
                </Space>

                {country == CustomerAddressType.USA && (
                  <Col xs={24} md={24}>
                    <Form.Item
                      rules={rules}
                      required
                      label="Địa chỉ"
                      name={"address"}
                    >
                      <Input
                        style={{ padding: "4px 11px" }}
                        autoComplete="new-password"
                        suffix={
                          <Button
                            type="link"
                            size="small"
                            onClick={() => setVisibleMap(true)}
                          >
                            Chọn địa chỉ
                          </Button>
                        }
                      />
                    </Form.Item>
                    <Form.Item rules={rules} required name={"city"} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item rules={rules} required name={"state"} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item rules={rules} required name={"zipCode"} hidden>
                      <Input />
                    </Form.Item>
                    <Form.Item rules={rules} required name={"street"} hidden>
                      <Input />
                    </Form.Item>
                  </Col>
                )}

                {country == CustomerAddressType.VN && (
                  <Col xs={24} md={24}>
                    <AddressSelect
                      form={form}
                      onChange={(data) => {
                        console.log("data ne", data);
                        addressValueRef.current = data;
                      }}
                    />
                  </Col>
                )}

                <Col xs={24} md={24}>
                  <Checkbox
                    checked={isShowReceptionInput}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        console.log("reset");

                        resetReceiverAddressFields();
                      }
                      setIsShowReceptionInput(e.target.checked);
                    }}
                  >
                    Điền thông tin người nhận
                  </Checkbox>
                </Col>

                {isShowReceptionInput && (
                  <>
                    <Divider
                      orientation="left"
                      orientationMargin={15}
                      style={{ fontSize: 18, color: "#3973b9" }}
                    >
                      Thông tin người nhận{" "}
                      <Button
                        hidden={country != "VN"}
                        onClick={handleCopyAddress}
                        icon={<CopyFilled />}
                        ghost
                        size="small"
                        type="primary"
                      >
                        Copy thông tin người gửi
                      </Button>
                    </Divider>
                    <Col xs={24} md={24}>
                      <Form.Item
                        style={{ marginBottom: "10px" }}
                        label="Họ tên người nhận"
                        name="receiverName"
                        className="form-field"
                        rules={rules}
                      >
                        <Input placeholder={""} autoComplete="" />
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: "10px" }}
                        label="Số điện thoại người nhận"
                        name="receiverPhone"
                        className="form-field"
                        rules={rules}
                      >
                        <Input placeholder={""} autoComplete="" />
                      </Form.Item>
                      <ReceiverAddressSelect
                        form={form}
                        onChange={(data) => ""}
                        ref={receiverAddressRef}
                      />
                    </Col>
                  </>
                )}

                <Col xs={24} md={24}>
                  <Button
                    style={{ marginTop: "10px" }}
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="user-form-btn"
                  >
                    Tạo tài khoản
                  </Button>
                </Col>
              </AppRowContainer>
            </Card>
          </div>
        </AppAnimateGroup>
      </Form>

      {visibleMap && (
        <AddressModal
          open={visibleMap}
          onClose={() => setVisibleMap(false)}
          onSubmitOk={(address, lat, long, city, state, zipCode, street) => {
            console.log({ address, city, state, zipCode });
            form.setFieldValue("address", address);
            form.setFieldValue("city", city);
            form.setFieldValue("state", state);
            form.setFieldValue("zipCode", zipCode);
            form.setFieldValue("street", street);
          }}
        />
      )}

      <Modal
        open={visibleResult}
        footer={null}
        centered
        width={550}
        closable={false}
      >
        <Result
          className="login-result"
          style={{ paddingBottom: "1em", paddingTop: "1em" }}
          status="success"
          title="Đăng ký tài khoản thành công!"
          subTitle={
            <div
              style={{ textAlign: "left", color: "#000", marginTop: "10px" }}
            >
              <ul>
                <li>
                  <b> Customer ID:</b> {result?.customer?.code}
                </li>
                <li>
                  <b>Tên đăng nhập:</b> {result?.customer?.username}
                </li>
                <li>
                  <b>Thông tin người gửi:</b>
                  <ul>
                    <li>
                      <b>Họ tên:</b> {result?.customer?.name}
                    </li>
                    <li>
                      <b>Điện thoại:</b> {result?.customer?.phone}
                    </li>
                    {result?.customer.addressType ==
                      CustomerAddressType?.USA && (
                      <li>
                        <b>Địa chỉ US:</b> {result?.customer?.address}
                      </li>
                    )}
                    {result?.customer?.addressType ==
                      CustomerAddressType.VN && (
                      <li>
                        <b>Địa chỉ VN:</b> {result?.customer?.address}
                      </li>
                    )}
                  </ul>
                </li>
                {result?.receiver && (
                  <li>
                    <b>Thông tin người nhận:</b>
                    <ul>
                      <li>
                        <b>Họ tên:</b> {result?.receiver?.name}
                      </li>
                      <li>
                        <b>Điện thoại:</b> {result?.receiver?.phone}
                      </li>
                      <li>
                        <b> Địa chỉ:</b>{" "}
                        {getAddress(
                          result?.receiver?.ward,
                          result?.receiver?.district,
                          result?.receiver?.city,
                          result?.receiver?.address
                        )}
                      </li>
                    </ul>
                  </li>
                )}
              </ul>

              <Alert
                type="warning"
                description={
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      Quý khách có thể gửi hàng về kho US theo cấu trúc sau:
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <b>First name:</b>{" "}
                      {docSoBangChu(result?.customer?.code || "")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <b>Last name:</b>{" "}
                      {getUserName(result?.customer?.name || "")}
                    </p>
                    <Descriptions>
                      <Descriptions.Item
                        style={{ padding: 0 }}
                        label="Address"
                        contentStyle={{ flexDirection: "column" }}
                        labelStyle={{ fontWeight: "bold" }}
                      >
                        <p style={{ marginBottom: 0 }}>
                          5015 W EDINGER AVE, STE S
                        </p>

                        <p style={{ marginBottom: 0 }}>SANTA ANA, CA 92704</p>

                        <p style={{ marginBottom: 0 }}>657 456 3892</p>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                }
              />

              <p className="text-center" style={{ marginTop: "10px" }}>
                <i>
                  Nếu quý khách cần hỗ trợ xin liên lạc NHA TRANG EXPRESS:{" "}
                  <br />
                  Phone/Viber/Zalo: 657 456 3892
                </i>
              </p>
            </div>
          }
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => navigation("/login")}
            >
              Đi đến trang đăng nhập
            </Button>,
          ]}
        />
      </Modal>
    </div>
  );
};

export default RegisterPage;
