import { FilterFilled, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  DatePicker,
  Popover,
  Select,
  Space,
} from "antd";
import { staffApi } from "api/staff.api";
import { OrderStatusTag } from "components/Tags/Tags";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Customer } from "types/customer";
import {
  OrderQuery,
  OrderStatus,
  orderStatusTrans,
  OrderType,
} from "types/order";
import { QueryParam } from "types/query";
import { Staff } from "types/staff";
import { arrayUniqueValueByKey } from "utils/array";

export const OrderFilter = ({
  query,
  onChange,
}: {
  query: OrderQuery;
  onChange: (value: OrderQuery) => void;
}) => {
  const [queryCustomer, setQueryCustomer] = useState<QueryParam>({
    page: 1,
    limit: 50,
  });
  const [queryStaff, setQueryStaff] = useState<QueryParam>({
    page: 1,
    limit: 50,
  });
  const [queryFlyTrip, setQueryFlyTrip] = useState<QueryParam>({
    page: 1,
    limit: 50,
  });
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [staffs, setStaff] = useState<Staff[]>([]);
  const [loading, setLoading] = useState(false);
  const [flyTrips, setFlyTrips] = useState([]);
  const handleFilterDate = (date: any) => {
    onChange({
      ...query,
      fromDate: date?.[0].format("YYYY-MM-DD"),
      toDate: date?.[1].format("YYYY-MM-DD"),
    });
  };

  const handleClearFilter = () => {
    onChange({
      page: query.page,
      limit: query.limit,
      type: OrderType.Ship,
    });
  };

  const debounceCustomerSearch = useCallback(
    debounce(
      (keyword) => setQueryCustomer({ ...queryCustomer, search: keyword }),
      300
    ),
    []
  );

  const debounceStaffSearch = useCallback(
    debounce(
      (keyword) => setQueryStaff({ ...queryStaff, search: keyword }),
      300
    ),
    []
  );

  const fetchStaff = async () => {
    setLoading(true);
    const res = await staffApi.findAll(queryStaff);
    setLoading(false);

    const resStaffs: Staff[] = res.data.data || [];

    const newStaff = arrayUniqueValueByKey(resStaffs.concat(staffs));

    setStaff(newStaff as Staff[]);
  };

  return (
    <>
      <Popover
        trigger={["click"]}
        placement="right"
        content={
          <>
            <div className="filter-item" style={{ marginBottom: "10px" }}>
              <label>Trạng thái đơn</label>
              <Select
                allowClear
                value={query.status}
                placeholder={"Trạng thái đơn"}
                style={{ width: "100%" }}
                onChange={(e) => onChange({ ...query, status: e })}
              >
                {Object.values(orderStatusTrans)
                  .filter((e) => e.value != OrderStatus.SaveUsa)
                  .map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      <OrderStatusTag status={item.value} />
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div className="filter-item" style={{ marginBottom: "10px" }}>
              <label htmlFor="">Thời gian</label>
              <DatePicker.RangePicker
                value={
                  query.fromDate && query.toDate
                    ? [
                        moment(query.fromDate, "YYYY-MM-DD"),
                        moment(query.toDate, "YYYY-MM-DD"),
                      ]
                    : null
                }
                format={"MM/DD/YYYY"}
                onChange={(value) => handleFilterDate(value)}
              />
            </div>

            <Button
              type="primary"
              block
              style={{ marginTop: "10px" }}
              onClick={handleClearFilter}
            >
              Xoá filter
            </Button>
          </>
        }
        title="Filter"
      >
        <Badge
          dot={
            !!query.fromDate ||
            !!query.toDate ||
            !!query.status ||
            !!query.customerId ||
            !!query.createdStaffId ||
            !!query.flyTripId
          }
          size="small"
        >
          <Button type="default" icon={<FilterFilled />}>
            Filter
          </Button>
        </Badge>
      </Popover>
    </>
  );
};
