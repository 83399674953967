import Cookies from "js-cookie";
import md5 from "md5";
import moment from "moment";
import { settings } from "setting";

export const getToken = () => {
  return Cookies.get("token");
};

export const setToken = (token: string) => {
  return Cookies.set("token", token);
};

export const generateHash = () => {
  const time = moment().unix();
  const hash = md5(`${settings.trackingHash}.${time}`);
  return { time, hash };
};
