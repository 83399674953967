import { SearchOutlined } from "@ant-design/icons";
import { Col, DatePicker, Input, Row, Select, Space, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getTitle } from "utils";
//@ts-ignore
import { Pagination } from "components/Pagination";
import { useProductPackage } from "hooks/useProductPackage";
import moment from "moment";
import { settings } from "setting";
import { Customer } from "types/customer";
import {
  ProductPackage,
  ProductPackageQuery,
  ProductPackageStatusTrans,
} from "types/productPackage";
import { unixToFullDate } from "utils/formatDate";

export const ProductPackagePageV2 = ({
  title,
  selectedCustomer,
}: {
  title: string;
  selectedCustomer?: Customer;
}) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<ProductPackageQuery>({
    page: 1,
    limit: 50,
    isParent: true,
    fromAt: moment().subtract(7, "day").startOf("day").unix(),
    toAt: moment().endOf("day").unix(),
  });
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);

  const { productPackages, fetchProductPackage, productPackagesTotal } =
    useProductPackage();
  const [selectedProductPackage, setSelectedProductPackage] =
    useState<ProductPackage>();
  const debounceSearch = useCallback(
    debounce((keyword) => setQuery({ ...query, search: keyword }), 500),
    []
  );

  const { RangePicker } = DatePicker;

  useEffect(() => {
    fetchProductPackage(query);
  }, [query]);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  const setTimeSummarySale = (dates: any) => {
    query.fromAt = undefined;
    query.toAt = undefined;

    if (dates) {
      query.fromAt = dates[0].startOf("day").unix();
      query.toAt = dates[1].endOf("day").unix();
    }

    setQuery({ ...query });
  };

  return (
    <div className="b-container" style={{ paddingTop: "1em" }}>
      <Row gutter={12} style={{ padding: "0 6px" }}>
        <Col
          span={24}
          style={{
            borderRight: "1px solid #efefef",
            paddingLeft: "6px",
          }}
        >
          <div className="filter-container">
            <Space wrap size={5}>
              <div className="filter-item">
                <label>Tìm kiếm</label>
                <Input
                  style={{ width: "350px" }}
                  allowClear
                  suffix={<SearchOutlined />}
                  placeholder="Search TRK#, tên, số phone, chi tiết hàng"
                  size="middle"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debounceSearch(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setQuery({ ...query, search, page: 1 });
                    }
                  }}
                />
              </div>{" "}
              <div className="filter-item">
                <label>Ngày tạo</label>
                <RangePicker
                  style={{ width: "250px" }}
                  ranges={{
                    "Hôm nay": [moment().startOf("day"), moment().endOf("day")],
                    "Tuần này": [
                      moment().startOf("week"),
                      moment().endOf("week"),
                    ],
                    "Tháng này": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                    "3 tháng gần nhất": [
                      moment().subtract(3, "month").startOf("month"),
                      moment().endOf("month"),
                    ],
                    "Năm nay": [
                      moment().startOf("year"),
                      moment().endOf("year"),
                    ],
                  }}
                  format={settings.dateFormat}
                  onChange={setTimeSummarySale}
                  size="middle"
                  value={
                    query.fromAt && query.toAt
                      ? [moment.unix(query.fromAt), moment.unix(query.toAt)]
                      : undefined
                  }
                />
              </div>{" "}
              <div className="filter-item">
                <label>Trạng thái</label>
                <Select
                  size="middle"
                  style={{ width: 150 }}
                  allowClear
                  onChange={(e) => {
                    query.status = e;
                    setQuery({ ...query });
                  }}
                >
                  {Object.values(ProductPackageStatusTrans).map((item) => (
                    <Select.Option key={item.value} value={item.value}>
                      <Tag color={ProductPackageStatusTrans[item.value]?.color}>
                        {ProductPackageStatusTrans[item.value]?.label}
                      </Tag>
                    </Select.Option>
                  ))}
                </Select>
              </div>{" "}
            </Space>
          </div>
          {/* //danh sach */}

          <Table
            rowKey={"id"}
            bordered
            className="table-striped-rows"
            expandable={{ showExpandColumn: false }}
            style={{ marginTop: "10px", width: "1200px" }}
            loading={loading}
            rowClassName={(record, index) =>
              record.id == selectedProductPackage?.id ? "active" : ""
            }
            id="id"
            dataSource={productPackages}
            pagination={false}
            scroll={{ x: "max-content" }}
          >
            <Column
              title="STT"
              render={(text, record: ProductPackage, index) => (
                <span>{record?.no || index + 1}</span>
              )}
            />
            <Column
              title="TRK Online #"
              render={(text, record: ProductPackage) => <>{record.name}</>}
            />
            <Column
              title="TRK"
              render={(text, record: ProductPackage) => (
                <>{record?.order?.trackingCode}</>
              )}
            />
            {/* <Column
              title="Khách hàng"
              render={(text, record: ProductPackage) => (
                <>
                  {record?.customer?.name} - {record?.customer?.phone}
                </>
              )}
            /> */}
            <Column
              title="Chi tiết hàng"
              render={(text, record: ProductPackage) => (
                <>
                  <ul style={{ margin: 0, paddingLeft: 20 }}>
                    {record.productPackageDetails
                      .filter((item) => item.name)
                      .map((e) => (
                        <li key={e.id}>{e.name}</li>
                      ))}
                  </ul>
                </>
              )}
            />
            <Column
              align="right"
              title="WT(lbs)"
              render={(text, record: ProductPackage) => (
                <>{record?.totalWeight}</>
              )}
            />
            <Column
              title="Ngày giờ tạo"
              render={(text, record: ProductPackage) => (
                <>{unixToFullDate(record.createdAt)}</>
              )}
            />
            {/* <Column
              title="Nhân viên"
              render={(text, record: ProductPackage) => (
                <>{record.createdStaff.name}</>
              )}
            /> */}

            <Column
              title="Trạng thái"
              render={(text, record: ProductPackage) => (
                <>
                  <Tag color={ProductPackageStatusTrans[record.status]?.color}>
                    {ProductPackageStatusTrans[record.status]?.label}
                  </Tag>
                </>
              )}
            />
          </Table>
          <Pagination
            total={productPackagesTotal}
            onChange={({ page, limit }) => setQuery({ ...query, page, limit })}
            currentPage={query.page}
            pageSize={query.limit}
          />
        </Col>
      </Row>

      {/* <TRKStatusModal
        open={visibleStatusModal}
        onClose={() => setVisibleStatusModal(false)}
        onSubmitOk={() => fetchProductPackage(query)}
        selectedKeys={selectedKeys}
      /> */}
    </div>
  );
};
export { ProductPackagePageV2 as default };
