import { MenuFoldOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Row, Select, Space, Table } from "antd";
import Column from "antd/lib/table/Column";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { getTitle } from "utils";
//@ts-ignore
import { orderApi } from "api/order.api";
import OrderDetailComponent from "components/OrderDetailComponent/OrderDetailComponent";
import { OrderStatusTag } from "components/Tags/Tags";
import { useAppHeight } from "hooks/useAppHeight";
import { useWindowSize } from "hooks/useWindowSize";
import { ModalStatus } from "types/modal";
import {
  Order,
  OrderQuery,
  OrderStatus,
  orderStatusTrans,
  OrderType,
} from "types/order";
import { OrderFilter } from "views/SaleReceiptPage/components/OrderFilter";
import { PaginationLite } from "components/Pagination/PaginationLite";

export const CreateOrderPage = ({ title }: { title: string }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<OrderQuery>({
    page: 1,
    limit: 50,
    type: OrderType.Ship,
  });
  const [data, setData] = useState<Order[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<ModalStatus>("update");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const { sideBarHeight } = useAppHeight();
  const debounceSearch = useCallback(
    debounce((keyword) => setQuery({ ...query, search: keyword }), 500),
    []
  );
  const { width } = useWindowSize();

  useEffect(() => {
    fetchData();
  }, [query]);

  useEffect(() => {
    document.title = getTitle(title);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const res = await orderApi.findAll(query);
    setLoading(false);
    setData(res.data.orders);
    setTotal(res.data.total);

    if (!selectedOrder && res.data.orders.length > 0) {
      setSelectedOrder(res.data.orders[0]);
    }
  };

  return (
    <>
      <Row gutter={12} style={{ padding: "0 6px" }}>
        <div
          className="order-history-sidebar"
          style={{ borderRight: "1px solid #efefef", paddingTop: "1em" }}
        >
          <Input
            allowClear
            suffix={<SearchOutlined />}
            placeholder="Search TRK#"
            size="middle"
            onChange={(e) => {
              setSearch(e.target.value);
              debounceSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setQuery({ ...query, search, page: 1 });
              }
            }}
          />
          <Space
            style={{ width: "100%", justifyContent: "end", marginTop: "5px" }}
          >
            <OrderFilter
              query={query}
              onChange={(newQuery) => {
                setQuery(newQuery);
              }}
            />
          </Space>

          <Table
            childrenColumnName="0"
            className="table-striped-rows"
            loading={loading}
            rowClassName={(record, index) =>
              record.id == selectedOrder?.id ? "active" : ""
            }
            id="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setStatus("update");
                  setSelectedOrder(record);
                  setVisibleDraw(false);
                },
              };
            }}
            dataSource={data}
            pagination={false}
            scroll={{ x: "max-content", y: sideBarHeight }}
          >
            <Column
              title={
                <Space
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <b>TRK#</b>
                  <b>Trạng thái</b>
                </Space>
              }
              render={(text, record: Order) => (
                <Space
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <b>{record.trackingCode}</b>
                  {record.status ? (
                    <OrderStatusTag status={record.status} />
                  ) : (
                    <></>
                  )}
                </Space>
              )}
            />
          </Table>
          <PaginationLite
            total={total}
            onChange={({ page, limit }) => setQuery({ ...query, page, limit })}
            currentPage={query.page}
            pageSize={query.limit}
          />
        </div>

        <div className="order-history-content">
          {!!width && width <= 900 && (
            <Button
              icon={<MenuFoldOutlined />}
              style={{ marginBottom: "10px" }}
              onClick={() => {
                setVisibleDraw(true);
              }}
            >
              Danh sách đơn hàng
            </Button>
          )}
          <OrderDetailComponent selectedOrder={selectedOrder} />
          {/* <AppContentView dataResult={selectedOrder} /> */}
        </div>
      </Row>
      {!!width && width <= 900 && (
        <Drawer
          headerStyle={{ display: "none" }}
          contentWrapperStyle={{ top: 0 }}
          bodyStyle={{ padding: 10 }}
          maskStyle={{ backgroundColor: "transparent" }}
          placement="left"
          width={300}
          onClose={() => setVisibleDraw(false)}
          open={visibleDraw}
        >
          <Input
            allowClear
            suffix={<SearchOutlined />}
            placeholder="Tìm kiếm"
            size="middle"
            onChange={(e) => {
              setSearch(e.target.value);
              debounceSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setQuery({ ...query, search, page: 1 });
              }
            }}
          />

          <Space
            style={{ width: "100%", justifyContent: "end", marginTop: "5px" }}
          >
            <div>
              <label>Trạng thái đơn</label>
              <br />
              <Select
                value={query.status}
                size="small"
                placeholder={"Trạng thái đơn"}
                onChange={(e) => setQuery({ ...query, status: e })}
                style={{ width: 150 }}
              >
                {Object.values(orderStatusTrans)
                  .filter((e) => e.value != OrderStatus.SaveUsa)
                  .map((item, index) => (
                    <Select.Option key={index} value={item.value}>
                      <OrderStatusTag status={item.value} />
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </Space>

          <Table
            childrenColumnName="0"
            className="table-striped-rows"
            loading={loading}
            rowClassName={(record, index) =>
              record.id == selectedOrder?.id ? "active" : ""
            }
            id="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setStatus("update");
                  setSelectedOrder(record);
                  setVisibleDraw(false);
                },
              };
            }}
            dataSource={data}
            pagination={false}
            scroll={{ x: "max-content", y: sideBarHeight }}
          >
            <Column
              title="TRK#"
              render={(text, record: Order) => (
                <Space
                  style={{ justifyContent: "space-between", width: "100%" }}
                >
                  <b>{record.trackingCode}</b>
                  <OrderStatusTag status={record.status} />
                </Space>
              )}
            />
          </Table>
        </Drawer>
      )}
    </>
  );
};
export { CreateOrderPage as default };
