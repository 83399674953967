import { Avatar, Button, Dropdown, Input, Layout, Menu, Space } from "antd";
import AppLogo from "../components/AppLogo";
import "./index.style.less";
// import {useIntl} from 'react-intl';
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import { useWindowSize } from "hooks/useWindowSize";
import TopNavbar from "layouts/components/TopNavbar";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { settings } from "setting";
import { userStore } from "store/userStore";
import AppNotifications from "../../AppNotifications/AppNotifications";

const AppHeader = ({ isCollapsed, onToggleSidebar }) => {
  const { Header } = Layout;
  const { Search } = Input;
  const navigate = useNavigate();
  const { width } = useWindowSize();
  // const {messages} = useIntl();
  const menuMobile = (
    <Menu>
      <AppNotifications />
      {/* <AppHeaderMessages />
      <AppLanguageSwitcher /> */}
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          userStore.logout();
          navigate("/");
        }}
      >
        Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header className="app-header" style={{ width: "100%", height: "75px" }}>
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
            padding: `0 ${width >= 800 ? "11vw" : "10px"}`,
          }}
        >
          <Space size={20}>
            <AppLogo />
            {userStore.token && <TopNavbar />}

            {/* {width >= 800 && <TopNavbar />} */}
          </Space>
          {width >= 800 && (
            <Space>
              {userStore.token ? (
                <>
                  <Dropdown overlay={menu}>
                    <Space size={5} style={{ cursor: "pointer" }}>
                      <Avatar icon={<UserOutlined />} />
                      {userStore.info.name}
                      <CaretDownOutlined />
                    </Space>
                  </Dropdown>
                </>
              ) : (
                <Space style={{ marginRight: "10px" }}>
                  <Button type="primary">
                    <Link to={"/sign-up"}>Đăng ký</Link>
                  </Button>
                  <Button type="primary" ghost>
                    <Link to={"/login"}>Đăng nhập</Link>
                  </Button>
                </Space>
              )}

              <div>{"v" + settings.version}</div>
            </Space>
          )}

          {width < 800 && !userStore.token && (
            <Space>
              <Button type="primary">
                <Link to={"/sign-up"}>Đăng ký</Link>
              </Button>
              <Button type="primary" ghost>
                <Link to={"/login"}>Đăng nhập</Link>
              </Button>
            </Space>
          )}
        </Space>
      </Header>
    </>
  );
};

export default observer(AppHeader);

AppHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
};
