import { Spin } from "antd";
import { useRouter } from "hooks/useRouter";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRoutes } from "router";
import { settings } from "setting";
import { permissionStore } from "store/permissionStore";
import { userStore } from "store/userStore";
import { getToken } from "utils/auth";

export const AuthProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const navigation = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();

    useEffect(() => {
      handleAuth();
      handleNavigation();

      return () => {};
    }, [navigation]);

    const handleNavigation = () => {
      const route = adminRoutes.find((e) => e.path == location.pathname);
      if (route?.children) {
        const findIndex = route.children.find((e) => e.index);
        const routePath =
          route.path + "/" + (findIndex?.path || route.children[0]?.path || "");
        navigation(routePath);
      }
    };

    const handleAuth = async () => {
      const token = getToken();
      if (!token) {
        navigation("/login");
      } else {
        try {
          await userStore.getProfile();
        } catch (error) {
          userStore.logout();
          navigation("/login");
        }
      }
      setIsLoaded(true);
    };

    if (isLoaded) {
      return <>{children}</>;
    } else {
      return (
        <>
          <Spin spinning={!isLoaded}></Spin>
        </>
      );
    }
  }
);
