import React from "react";
import { Button, Layout, Row, Col } from "antd";
import "./AppFooter.style.less";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import { LeftCircleFilled } from "@ant-design/icons";
import WrapperStatistic from "antd/lib/statistic/Statistic";

const { Footer } = Layout;

const AppFooter = () => {
  const { footer } = useLayoutContext();

  if (footer || true) {
    return (
      <div className="footer-wrapper">
        <Footer
          className="app-main-footer footer"
          style={{ maxWidth: "1400px", margin: "auto" }}
        >
          <Row gutter={60} style={{ width: "100%" }}>
            <Col
              xl={6}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 0,
              }}
            >
              <h3 style={{ fontSize: "18px", fontWeight: "600" }}>ADDRESS</h3>
              <p>5015 W Edinger Ave, Ste S</p>
              <p>Santa Ana, CA 92704</p>
              <p>
                <a href="nhatrangexpressusa.com">nhatrangexpressusa.com</a>{" "}
              </p>
            </Col>
            <Col
              xl={6}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 0,
              }}
            >
              <h3 style={{ fontSize: "18px", fontWeight: "600" }}>CONTACT</h3>
              <p>
                Phone/Viber/Zalo: <a href="tel:6574563892">657 456 3892</a>{" "}
              </p>
              <p>
                Email:{" "}
                <a href="mailto:nhatrangexpress.usa@gmail.com">
                  nhatrangexpress.usa@gmail.com
                </a>{" "}
              </p>
              <p>
                Fanpage:{" "}
                <a
                  target={"_blank"}
                  href="https://facebook.com/nhatrangexpressusa"
                >
                  facebook.com/nhatrangexpressusa
                </a>{" "}
              </p>
            </Col>
            <Col
              xl={6}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 0,
              }}
            >
              <h3 style={{ fontSize: "18px", fontWeight: "600" }}>SERVICES</h3>

              <p>
                <a
                  target={"_blank"}
                  href="https://nhatrangexpressusa.com/services"
                >
                  Xem chi tiết / Details
                </a>{" "}
              </p>
            </Col>
            <Col
              xl={6}
              xs={24}
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 0,
              }}
            >
              <h3 style={{ fontSize: "18px", fontWeight: "600" }}>
                TERMS OF SERVICE
              </h3>

              <p>
                <a target={"_blank"} href="https://nhatrangexpressusa.com/tos">
                  Xem chi tiết / Details
                </a>{" "}
              </p>
            </Col>
          </Row>
        </Footer>
      </div>
    );
  }
};

export default AppFooter;
