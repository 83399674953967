import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const orderApi = {
  findAll: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/order",
      params,
    }),
  findOrderByTRK: (params: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/order/tracking",
      params,
    }),
    findOrderByMultipleTRK: (params: any): AxiosPromise<any> =>
      request({
        url: "/v1/customer/order/tracking/multiple",
        params,
      }),
  findOne: (orderId: number): AxiosPromise<any> =>
    request({
      url: "/v1/customer/order/" + orderId,
    }),
};
