export const PostCodeComponent = ({
  postCode,
  limit = 2,
}: {
  postCode: string;
  limit?: number;
}) => {
  const postCodes = postCode.split(",");
  console.log("postCodes", postCodes.length);

  if (postCodes.length > limit) {
    return (
      <div>
        <b>
          Mã bưu gửi:{" "}
          <a
            target={"_blank"}
            href={`https://www.ems.com.vn/tra-cuu/tra-cuu-buu-gui?code=${postCodes
              .map((item, index) => item.trim())
              .join(", ")}`}
            className="tooltip-text"
          >
            Xem {postCodes.length} mã
          </a>
        </b>
      </div>
    );
  } else if (postCodes.length) {
    return (
      <>
        <b>Mã bưu gửi: </b>
        <a
          className="tooltip-text"
          href={`https://www.ems.com.vn/tra-cuu/tra-cuu-buu-gui?code=${postCodes
            .map((item, index) => item.trim())
            .join(", ")}`}
          target={"_blank"}
        >
          {postCodes.map((item, index) => item.trim()).join(", ")}
        </a>
      </>
    );
  }
  return <></>;
};
