import { DownOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu, Space } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { userStore } from "store/userStore";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
import "./index.style.less";

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  // const {logout} = useAuthMethod();
  // const {user} = useAuthUser();
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const logout = () => {
    userStore.logout();
    navigate("/login");
  };

  const user = {
    displayName: userStore.info.name,
  };

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div onClick={() => navigate("setting/account")}>
              Quản lý tài khoản
            </div>
          ),
        },
        {
          key: "2",
          label: <div onClick={() => logout()}>Đăng xuất</div>,
        },
      ]}
    />
  );

  // const menu = (
  //   <List className="dropdown-list">
  //     <List.Item onClick={() => navigate("setting/account")}>
  //       Quản lý tài khoản
  //     </List.Item>
  //     <List.Item onClick={() => logout()}>Đăng xuất</List.Item>
  //   </List>
  // );

  return (
    <>
      {hasColor ? (
        <div
          // style={{
          //   backgroundColor: isSidebarBgImage
          //     ? ""
          //     : sidebarColorSet.sidebarHeaderColor,
          //   color: sidebarColorSet.sidebarTextColor,
          // }}
          className={clsx("cr-user-info cr-user-info-hasColor", {
            light: themeMode === "light",
          })}
        >
          <Dropdown
            className="user-profile-dropdown"
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}
          >
            <a className="cr-user-info-inner ant-dropdown-link">
              <Space>
                {user.photoURL ? (
                  <Avatar className="cr-user-info-avatar" src={user.photoURL} />
                ) : (
                  <Avatar className="cr-user-info-avatar">
                    {getUserAvatar()}
                  </Avatar>
                )}
                <DownOutlined />
              </Space>

              {/* <span className="cr-user-info-content">
                <span className="cr-user-name-info">
                  <h3
                    className={clsx("cr-user-name text-truncate", {
                      light: themeMode === "light",
                    })}
                  >
                    {user.displayName ? user.displayName : "Admin user"}
                  </h3>
                  <span className="cr-user-arrow">
                    <FaChevronDown />
                  </span>
                </span>
                <span className="cr-user-designation text-truncate"></span>
              </span> */}
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx("cr-user-info", {
            light: themeMode === "light",
          })}
        >
          <Dropdown
            className="user-profile-dropdown"
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}
          >
            <a className="cr-user-info-inner ant-dropdown-link">
              {user.photoURL ? (
                <Avatar className="cr-user-info-avatar" src={user.photoURL} />
              ) : (
                <Avatar className="cr-user-info-avatar">
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className="cr-user-info-content">
                <span className="cr-user-name-info">
                  <h3
                    className={clsx("cr-user-name text-truncate", {
                      light: themeMode === "light",
                    })}
                  >
                    {user.displayName ? user.displayName : "admin user "}
                  </h3>
                  <span className="cr-user-arrow">
                    <FaChevronDown />
                  </span>
                </span>
                <span className="cr-user-designation text-truncate">
                  System Manager
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
