import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const orderLogApi = {
  findOne: (params: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/orderLog",
      params,
    }),
};
