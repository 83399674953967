import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  DiscountType,
  Order,
  OrderDetailType,
  OrderForm,
  ReceiveType,
} from "types/order";

import { font } from "../assets/fonts/helvetica";
import { unixToDate } from "./formatDate";

// import { font } from "../assets/fonts/arial";
import { formatNumber, formatVND } from "utils";
import { generateTrkLink } from "./trackingLink";
import { SVGToImage } from "./svgToPng";

const marginLeft = 60;
const lineHeight = 5;
const paddingLeft = 5;

export interface ITableData {
  name: string;
  quantity: number;
  rate: number;
  total: number;
}

export const htmlToPdf = (dom: string, scale = 4): Promise<jsPDF> => {
  return new Promise((resolve) => {
    const htmlElement = document.querySelector(dom) as HTMLElement;

    console.log("htmle", htmlElement);

    if (htmlElement) {
      html2canvas(htmlElement, { scale }).then((canvas) => {
        const imageData = canvas.toDataURL("image/jpg");

        var hratio = canvas.height / canvas.width;

        var pdf = new jsPDF("p", "mm", [105, 148]);

        var width = pdf.internal.pageSize.getWidth();

        const height = hratio * width;

        console.log("hratio", hratio);
        console.log("canvas.height", canvas.height);
        console.log("canvas.width", canvas.width);
        console.log("pageSize.getWidth", width);
        console.log(" hratio * width", height);

        pdf.addImage(imageData, "JPEG", 0, 0, width, height);

        resolve(pdf);
      });
    }
  });
};

export const renderShippingFormPDF = async (
  detail?: OrderForm,
  pdf?: jsPDF
) => {
  if (!detail) return;
  let startLine = 10;
  let marginLeft = 60;
  let lineHeight = 5;
  let paddingLeft = 5;
  var doc = pdf ? pdf : new jsPDF("p", "mm", [105, 148]);

  const myFont = font;
  // add the font to jsPDF
  doc.addFileToVFS("MyFont.ttf", myFont);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");
  doc.setFontSize(11);

  //Left
  doc.text("SHIPPING FORM", paddingLeft, startLine);
  doc.text(
    `Date: ${unixToDate(detail.createdAt)}`,
    paddingLeft,
    (startLine += lineHeight)
  );

  renderBrandInfo(doc, detail);

  const base64 = await renderQRCode(detail.trackingCode);

  //@ts-ignore
  doc.addImage(base64, "png", paddingLeft, 18, 20, 20);

  startLine = renderAddressInfo(startLine - 5, doc, detail, "LARGER");

  //Thong tin kien hang
  doc.text(
    "Commodity (Thông tin kiện hàng): GIFT",
    paddingLeft,
    (startLine += 10)
  );
  doc.text("Pieces/Số kiện: 1", paddingLeft, startLine + lineHeight);
  doc.text(
    `Weight/Trọng lượng (lbs): ${detail?.totalWeight}`,
    marginLeft - 20,
    (startLine += lineHeight)
  );

  autoTable(doc, {
    head: [["Contents Description / Chi Tiết Hàng Gửi"]],
    body: [
      [
        detail?.orderDetails
          .filter((e) => e.type == OrderDetailType.Custom)
          .map((item, index) => item.name),
      ],
      // ...
    ],
    theme: "grid",
    styles: {
      lineColor: [0, 0, 0],
      textColor: "#000",
      fontSize: 8,
      font: "MyFont",
    },

    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.2,
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      halign: "center",
      font: "MyFont",
      lineWidth: 0.1,
      lineColor: "#000",
    },
    tableWidth: 95,
    margin: { left: paddingLeft },
    startY: (startLine += 5),
  });

  try {
    return doc;
  } catch (error) {
    throw error;
  }
};

const getPositionCenter = (pageWidth: number, textWidth: number) => {
  const x = pageWidth / 2 - textWidth / 2;
  return x;
};

export const openPDFInNewTab = (pdf: any) => {
  const url = URL.createObjectURL(pdf.output("blob"));
  window.open(url, "_blank");
};

export const renderShippingFormLitePDF = async (
  detail?: OrderForm,
  pdf?: jsPDF
) => {
  if (!detail) return;
  let startLine = 10;
  let marginLeft = 60;
  let lineHeight = 5;
  let paddingLeft = 5;
  var doc = pdf ? pdf : new jsPDF("p", "mm", [105, 148]);

  const myFont = font;
  // add the font to jsPDF
  doc.addFileToVFS("MyFont.ttf", myFont);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");
  doc.setFontSize(11);

  //Left
  doc.text("SHIPPING FORM", paddingLeft, 10);
  doc.text(
    `Date: ${unixToDate(detail.createdAt)}`,
    paddingLeft,
    (startLine += lineHeight)
  );
  // doc.text(0, 40, "align - center ", { align: "center" });

  startLine = renderAddressInfo(startLine - 5, doc, detail, "LARGER");

  //Thong tin kien hang
  doc.text(
    "Commodity (Thông tin kiện hàng): GIFT",
    paddingLeft,
    (startLine += 10)
  );
  doc.text("Pieces/Số kiện: 1", paddingLeft, (startLine += lineHeight));
  doc.text(
    `Weight/Trọng lượng (lbs): ${detail?.totalWeight}`,
    paddingLeft,
    (startLine += lineHeight)
  );

  renderBrandInfo(doc, detail);

  try {
    const png = await SVGToImage({
      svg: document.getElementById("trackingUrlQR"),
      mimetype: "image/png",
      width: 500,
      quality: 1,
    });

    doc.addImage(png, "png", paddingLeft, 18, 20, 20);
    return doc;
  } catch (error) {
    console.log("eeerrr", error);

    throw error;
  }
};

export const renderFullReceiptPDF = async (detail?: OrderForm) => {
  var doc = new jsPDF("p", "mm", [105, 148]);
  //@ts-ignore
  doc = await renderShippingReceiptPDF(detail, doc);
  doc.addPage();
  //@ts-ignore
  doc = await renderShippingFormPDF(detail, doc);
  doc.addPage();
  //@ts-ignore
  doc = await renderShippingFormLitePDF(detail, doc);
  return doc;
};

export const renderShippingReceiptPDF = async (
  detail?: OrderForm,
  pdf?: jsPDF
) => {
  if (!detail) return;
  let startLine = 10;
  let marginLeft = 60;
  let lineHeight = 5;
  let paddingLeft = 5;
  const height = 148;
  const width = 105;

  var doc = pdf ? pdf : new jsPDF("p", "mm", [width, height]);

  const myFont = font;
  // add the font to jsPDF
  doc.addFileToVFS("MyFont.ttf", myFont);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");

  //Left
  doc.setFontSize(11);
  doc.text("SHIPPING RECEIPT", paddingLeft, 10);
  doc.text(
    `Date: ${unixToDate(detail.createdAt)}`,
    paddingLeft,
    (startLine += lineHeight)
  );
  doc.setFontSize(9);

  renderBrandInfo(doc, detail);

  const base64 = await renderQRCode(detail.trackingCode);

  //@ts-ignore
  doc.addImage(base64, "png", paddingLeft, 18, 20, 20);

  startLine = renderAddressInfo(startLine - 5, doc, detail, "LARGER");

  document.querySelector(`#qr_code img`)?.remove();

  const tableData = generateTableData(detail);

  const body = tableData.map((item) => [
    item.name,
    formatNumber(item.quantity),
    formatNumber(item.rate),
    formatNumber(item.total),
  ]);

  autoTable(doc, {
    head: [["Description", "Wt(lbs)/Qty", "Rate", "Subtotal"]],
    body,
    theme: "grid",
    columnStyles: {
      0: { halign: "left", minCellHeight: 1 },
      1: { cellWidth: 17, minCellHeight: 1 },
      2: { cellWidth: 14, minCellHeight: 1 },
      3: { cellWidth: 14, minCellHeight: 1 },
    },
    styles: {
      lineColor: [0, 0, 0],
      textColor: "#000",
      fontSize: 8,
      font: "MyFont",
      halign: "center",
      minCellHeight: 1,
      cellPadding: 1,
    },

    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.2,
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      halign: "center",
      font: "MyFont",
      lineWidth: 0.1,
      lineColor: "#000",
      fontSize: 8,
    },
    tableWidth: 95,
    margin: { left: paddingLeft },
    showHead: "firstPage",
    startY: (startLine += 6),
  });
  //@ts-ignore
  const lastTable = doc.lastAutoTable;
  console.log("lastTable", lastTable);
  // startLine += tableData.length * 5 + 10;
  startLine = lastTable.finalY;

  if (startLine + 30 > height) {
    doc.addPage();
    startLine = 10;
  }

  let subTotalLine = startLine;
  doc.text(
    `Tổng số lbs: ${formatNumber(detail?.totalWeight)}`,
    paddingLeft,
    (startLine += lineHeight)
  );

  doc.text(
    `Tổng số kiện: ${detail.numOfPacks}`,
    paddingLeft,
    (startLine += lineHeight)
  );
  doc.text(
    `Nhân viên: ${detail.createdStaff.name}`,
    paddingLeft,
    (startLine += 1.3 * lineHeight)
  );

  doc.text(`Subtotal:`, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(Number(detail.moneyTotal.toFixed(2)))}`,
    width -
      paddingLeft -
      doc.getTextWidth(`${formatVND(Number(detail.moneyTotal.toFixed(2)))}`),
    (subTotalLine += lineHeight)
  );

  //discount
  if (detail.moneyDiscount > 0) {
    const discountValue =
      detail.discountType == DiscountType.Amount
        ? detail.moneyDiscount
        : detail.discountPercent;

    const text = `${
      detail.discountType == DiscountType.Amount ? "$" : ""
    }${formatNumber(discountValue)}${
      detail.discountType == DiscountType.Percent ? "%" : ""
    }`;

    doc.text(`Discount:`, marginLeft, subTotalLine + lineHeight);
    doc.text(
      text,
      width - paddingLeft - doc.getTextWidth(text),
      (subTotalLine += lineHeight)
    );
  }
  //end-discount

  doc.setFontSize(10);
  doc.text(`Total: `, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(detail.moneyFinal)}`,
    width - paddingLeft - doc.getTextWidth(`${formatVND(detail.moneyFinal)}`),
    (subTotalLine += lineHeight)
  );
  doc.setLineWidth(0.1);
  doc.line(marginLeft, subTotalLine + 2, marginLeft + 40, (subTotalLine += 2)); // horizontal line
  doc.setFontSize(8);
  doc.text(`Paid:`, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(detail.moneyTransfer)}`,
    width -
      paddingLeft -
      doc.getTextWidth(`${formatVND(detail.moneyTransfer)}`),
    (subTotalLine += lineHeight)
  );

  //balance
  if (
    detail &&
    detail?.moneyFinal > detail?.moneyDeposit + detail?.moneyTransfer
  ) {
    const balance =
      detail?.moneyFinal - (detail?.moneyDeposit + detail?.moneyTransfer);
    doc.text(`Balance:`, marginLeft, subTotalLine + lineHeight);
    doc.text(
      `${formatVND(balance)}`,
      width - paddingLeft - doc.getTextWidth(`${formatVND(balance)}`),
      (subTotalLine += lineHeight)
    );
  }
  //balance
  doc.setFontSize(6);
  var xCenter = getPositionCenter(
    105,
    doc.getTextWidth(
      `Please review our terms of service at: nhatrangexpressusa.com/tos`
    )
  );
  doc.text(
    `Please review our terms of service at: nhatrangexpressusa.com/tos`,
    xCenter,
    height - 7
  );
  var xCenter = getPositionCenter(
    105,
    doc.getTextWidth(`Thank you for using Nha Trang Express service!`)
  );
  doc.text(
    `Thank you for using Nha Trang Express service!`,
    xCenter,
    height - 5
  );

  try {
    return doc;
  } catch (error) {
    console.log("eeerrr", error);

    throw error;
  }
};

const generateTableData = (orderDetail: Order) => {
  //Gom tất cả các loại hàng về 1 kieu dữ liệu duy nhất
  let tableData: ITableData[] = [];
  let itemData: {
    name: string;
    quantity: number;
    rate: number;
    total: number;
  };
  orderDetail?.orderDetails.forEach((order) => {
    if (order.type == OrderDetailType.Custom) {
      itemData = {
        name: order.name,
        quantity: order.cWeight,
        rate: order.price,
        total: Number((order.cWeight * order.price).toFixed(2)),
      };
    }

    if (order.type == OrderDetailType.Product) {
      itemData = {
        name: order.product.name,
        quantity: order.quantity,
        rate: order.price,
        total: Number((order.quantity * order.price).toFixed(2)),
      };
    }

    if (order.type == OrderDetailType.SubCharge) {
      itemData = {
        name: order.name,
        quantity: order.quantity,
        rate: order.price,
        total: Number((order.quantity * order.price).toFixed(2)),
      };
    }

    if (itemData) {
      tableData.push(itemData);
    }
  });
  return tableData;
};

export const renderLabelPDF = async (detail?: OrderForm) => {
  if (!detail) return;
  let startLine = 10;
  let marginLeft = 60;
  let lineHeight = 5;
  let paddingLeft = 5;
  const height = 148;
  const width = 105;
  var doc = new jsPDF("p", "mm", [width, height]);

  const myFont = font;
  // add the font to jsPDF
  doc.addFileToVFS("MyFont.ttf", myFont);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");

  //Left
  doc.setFontSize(12);
  doc.text(
    `TRK#: ${detail.trackingCode}`,
    paddingLeft,
    (startLine += lineHeight)
  );
  doc.text(`Số kiện / PCS: 2`, paddingLeft, (startLine += 2 * lineHeight));

  doc.text(`From: `, paddingLeft, startLine + 2 * lineHeight);
  doc.text(
    `${detail.customer.name}`,
    paddingLeft + 14,
    (startLine += 2 * lineHeight)
  );
  doc.text(`To:`, paddingLeft, startLine + 2 * lineHeight);
  doc.text(
    `${detail.receiverName}`,
    paddingLeft + 14,
    (startLine += 2 * lineHeight)
  );
  var textLines = doc.splitTextToSize(
    detail.receiverAddress,
    width - paddingLeft * 2 - 14
  );

  doc.text(textLines, paddingLeft + 14, (startLine += 3 * textLines.length));

  startLine += 3.5 * textLines.length;

  doc.text(
    `Tel: ${detail.receiverPhone}`,
    paddingLeft + 14,
    (startLine += lineHeight)
  );
  doc.line(paddingLeft, startLine + 5, width - paddingLeft, (startLine += 5)); //

  //page2
  const qr2position = startLine + lineHeight + 7;
  doc.text(
    `TRK#: ${detail.trackingCode}`,
    paddingLeft,
    (startLine += lineHeight + 10)
  );
  doc.text(`Số kiện / PCS: 2`, paddingLeft, (startLine += 2 * lineHeight));
  doc.text(`From: `, paddingLeft, startLine + 2 * lineHeight);
  doc.text(
    `${detail.customer.name}`,
    paddingLeft + 14,
    (startLine += 2 * lineHeight)
  );
  doc.text(`To:`, paddingLeft, startLine + 2 * lineHeight);
  doc.text(
    `${detail.receiverName}`,
    paddingLeft + 14,
    (startLine += 2 * lineHeight)
  );
  var textLines = doc.splitTextToSize(
    detail.receiverAddress,
    width - paddingLeft * 2 - 14
  );

  doc.text(textLines, paddingLeft + 14, (startLine += 3 * textLines.length));

  startLine += 3.5 * textLines.length;

  doc.text(
    `Tel: ${detail.receiverPhone}`,
    paddingLeft + 14,
    (startLine += lineHeight)
  );

  try {
    const png = await SVGToImage({
      svg: document.getElementById("trackingUrlQR"),
      mimetype: "image/png",
      width: 500,
      quality: 1,
    });

    doc.addImage(png, "png", width - paddingLeft - 20, 5, 20, 20);

    doc.addImage(png, "png", width - paddingLeft - 20, qr2position, 20, 20);
    return doc;
  } catch (error) {
    throw error;
  }
};

export const renderSaleReceipt = async (detail?: OrderForm, pdf?: jsPDF) => {
  if (!detail) return;
  let startLine = 10;
  let marginLeft = 60;
  let lineHeight = 5;
  let paddingLeft = 5;
  const height = 148;
  const width = 105;

  var doc = pdf ? pdf : new jsPDF("p", "mm", [width, height]);

  const myFont = font;
  // add the font to jsPDF
  doc.addFileToVFS("MyFont.ttf", myFont);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");

  //Left
  doc.setFontSize(10);
  doc.text("SALES RECEIPT", paddingLeft, 10);
  doc.text(
    `Date: ${unixToDate(detail.createdAt)}`,
    paddingLeft,
    (startLine += lineHeight)
  );
  doc.text(`Receipt#: ${detail.code}`, paddingLeft, (startLine += lineHeight));
  doc.setFontSize(8);
  // doc.text(0, 40, "align - center ", { align: "center" });

  //Nguoi sender
  let senderLine = startLine;
  doc.text(
    `Customer / Khách hàng: ${detail?.customer?.name || "--"}`,
    paddingLeft,
    (startLine += 20)
  );
  doc.text(
    `Customer ID: ${detail?.customer?.code || "--"}`,
    paddingLeft,
    (startLine += lineHeight)
  );

  doc.text(
    `Phone: ${detail?.customer?.phone || "--"}`,
    paddingLeft,
    (startLine += lineHeight)
  );

  startLine = Math.max(senderLine, startLine);

  const body = detail.orderDetails.map((item) => [
    item.product.name,
    formatNumber(item.quantity),
    formatNumber(item.price),
    formatNumber(item.quantity * item.price),
  ]);

  autoTable(doc, {
    head: [["Description", "Wt(lbs)/Qty", "Rate", "Subtotal"]],
    body,
    theme: "grid",
    columnStyles: {
      0: { halign: "left" },
      1: { cellWidth: 19 },
      2: { cellWidth: 15 },
      3: { cellWidth: 15 },
    },
    styles: {
      lineColor: [0, 0, 0],
      textColor: "#000",
      fontSize: 8,
      font: "MyFont",
      halign: "center",
    },

    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.2,
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      halign: "center",
      font: "MyFont",
      lineWidth: 0.1,
      fontSize: 8,
      lineColor: "#000",
    },
    tableWidth: 95,
    margin: { left: paddingLeft },
    startY: (startLine += 5),
  });
  //@ts-ignore
  const lastTable = doc.lastAutoTable;
  console.log("lastTable", lastTable);
  // startLine += tableData.length * 5 + 10;
  startLine = lastTable.finalY;

  let subTotalLine = startLine;
  doc.text(
    `Nhân viên: ${detail.createdStaff.name}`,
    paddingLeft,
    (startLine += 1.3 * lineHeight)
  );

  doc.text(`Subtotal:`, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(Number(detail.moneyTotal.toFixed(2)))}`,
    width -
      paddingLeft -
      doc.getTextWidth(`${formatVND(Number(detail.moneyTotal.toFixed(2)))}`),
    (subTotalLine += lineHeight)
  );

  //discount
  if (detail.moneyDiscount > 0) {
    const discountValue =
      detail.discountType == DiscountType.Amount
        ? detail.moneyDiscount
        : detail.discountPercent;

    const text = `${
      detail.discountType == DiscountType.Amount ? "$" : ""
    }${formatNumber(discountValue)}${
      detail.discountType == DiscountType.Percent ? "%" : ""
    }`;

    doc.text(`Discount:`, marginLeft, subTotalLine + lineHeight);
    doc.text(
      text,
      width - paddingLeft - doc.getTextWidth(text),
      (subTotalLine += lineHeight)
    );
  }
  //end-discount

  //tax
  if (!detail.isNoTax) {
    doc.text(`Tax:`, marginLeft, subTotalLine + lineHeight);
    doc.text(
      `${formatVND(detail.moneyVat)}`,
      width - paddingLeft - doc.getTextWidth(`${formatVND(detail.moneyVat)}`),
      (subTotalLine += lineHeight)
    );
  }
  //end-tax
  doc.setFontSize(12);
  doc.text(`Total: `, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(detail.moneyFinal)}`,
    width - paddingLeft - doc.getTextWidth(`${formatVND(detail.moneyFinal)}`),
    (subTotalLine += lineHeight)
  );
  doc.setLineWidth(0.1);
  doc.line(marginLeft, subTotalLine + 2, marginLeft + 35, (subTotalLine += 2)); // horizontal line
  doc.setFontSize(10);
  doc.text(`Paid:`, marginLeft, subTotalLine + lineHeight);
  doc.text(
    `${formatVND(detail.moneyTransfer)}`,
    width -
      paddingLeft -
      doc.getTextWidth(`${formatVND(detail.moneyTransfer)}`),
    (subTotalLine += lineHeight)
  );

  //balance
  if (
    detail &&
    detail?.moneyFinal > detail?.moneyDeposit + detail?.moneyTransfer
  ) {
    const balance =
      detail?.moneyFinal - (detail?.moneyDeposit + detail?.moneyTransfer);
    doc.text(`Balance:`, marginLeft, subTotalLine + lineHeight);
    doc.text(
      `${formatVND(balance)}`,
      width - paddingLeft - doc.getTextWidth(`${formatVND(balance)}`),
      (subTotalLine += lineHeight)
    );
  }

  //balance

  renderBrandInfo(doc, detail, false);

  doc.setFontSize(8);
  var xCenter = getPositionCenter(
    105,
    doc.getTextWidth(
      `Please review our terms of service at: nhatrangexpressusa.com/tos`
    )
  );
  doc.text(
    `Please review our terms of service at: nhatrangexpressusa.com/tos`,
    xCenter,
    height - 10
  );
  var xCenter = getPositionCenter(
    105,
    doc.getTextWidth(`Thank you for using Nha Trang Express service!`)
  );
  doc.text(
    `Thank you for using Nha Trang Express service!`,
    xCenter,
    height - 5
  );

  try {
    return doc;
  } catch (error) {
    console.log("eeerrr", error);

    throw error;
  }
};

const renderAddressInfo = (
  startLine: number,
  doc: jsPDF,
  detail: Order,
  size: "SMALL" | "MEDIUM" | "LARGER"
) => {
  const addressSizes = {
    SMALL: { fontSize: 8, lineHeightFactor: 1.75, lineHeight: undefined },
    MEDIUM: { fontSize: 10, lineHeightFactor: 1.5, lineHeight: 5.5 },
    LARGER: { fontSize: 12, lineHeightFactor: 1.4, lineHeight: 6 },
  };
  const lineHeightFactor = addressSizes[size]?.lineHeightFactor;
  const fontSize = addressSizes[size]?.fontSize;
  const addressLineHeight = addressSizes[size]?.lineHeight || lineHeight;

  //Nguoi sender
  doc.setFontSize(fontSize);
  let senderLine = startLine;
  doc.text(`Sender / Người gửi:`, paddingLeft, (startLine += 35));
  doc.text(
    `Customer ID: ${detail?.customer?.code}`,
    paddingLeft,
    (startLine += addressLineHeight)
  );

  var textLines = doc.splitTextToSize(detail.customer.name, 45);
  doc.text(textLines, paddingLeft, (startLine += addressLineHeight), {
    lineHeightFactor,
  });

  var blockHeight = getLinesHeight(textLines, doc, addressLineHeight);

  startLine += blockHeight;

  var textLines = doc.splitTextToSize(acronymAddress(detail.senderAddress), 40);

  doc.text(textLines, paddingLeft, (startLine += addressLineHeight), {
    lineHeightFactor,
  });

  var blockHeight = getLinesHeight(textLines, doc, addressLineHeight);

  startLine += blockHeight;

  doc.text(`Phone:`, paddingLeft, startLine + addressLineHeight);
  var textLines = getPhoneLines(detail.senderPhone);
  var phoneWidth = doc.getTextWidth(`Phone: `);
  doc.text(
    textLines,
    paddingLeft + phoneWidth,
    (startLine += addressLineHeight),
    {
      lineHeightFactor,
    }
  );

  //Nguoi nhan
  doc.text("Receipt / Người nhận:", marginLeft - 10, (senderLine += 35));
  doc.text(
    `${
      detail.receiveType == ReceiveType.Door
        ? "Deliver To Door"
        : "Deliver To Station"
    }`,
    marginLeft - 10,
    (senderLine += addressLineHeight)
  );

  var textLines = doc.splitTextToSize(detail.receiverName, 50);
  doc.text(textLines, marginLeft - 10, (senderLine += addressLineHeight), {
    lineHeightFactor,
  });

  var blockHeight = getLinesHeight(textLines, doc, addressLineHeight);

  senderLine += blockHeight;

  //wrap text
  var textLines = doc.splitTextToSize(
    acronymAddress(detail.receiverAddress),
    45
  );

  doc.text(textLines, marginLeft - 10, (senderLine += addressLineHeight), {
    lineHeightFactor,
  });

  var blockHeight = getLinesHeight(textLines, doc, addressLineHeight);
  senderLine += blockHeight;

  // var textLines = getPhoneLines(detail.receiverPhone);
  var textLines = getPhoneLines(detail.receiverPhone);

  doc.text(`Phone:`, marginLeft - 10, senderLine + addressLineHeight);
  var phoneWidth = doc.getTextWidth(`Phone: `);
  doc.text(
    textLines,
    marginLeft - 10 + phoneWidth,
    (senderLine += addressLineHeight),
    {
      lineHeightFactor,
    }
  );
  senderLine += 1.5;

  return (startLine = Math.max(senderLine, startLine));
};

const getPhoneLines = (phone: string) => {
  const lines = phone.split(",").map((item) => item.trim()) as any;

  return lines;
};

const getLinesHeight = (textLines: any[], doc, customLineHeight?: number) => {
  var lines = textLines.length; // splitted text is a string array

  var blockHeight = lines * (customLineHeight ? customLineHeight : lineHeight);
  return Math.floor(
    blockHeight - (customLineHeight ? customLineHeight : lineHeight)
  );
};

const renderBrandInfo = (
  doc: jsPDF,
  detail: Order,
  showTRK: boolean = true
) => {
  let xCenter = getPositionCenter(105, doc.getTextWidth("NHA TRANG EXPRESS"));
  doc.text("NHA TRANG EXPRESS", xCenter + 20, 10);
  doc.setFontSize(8);
  xCenter = getPositionCenter(
    105,
    doc.getTextWidth("5015 W Edinger Ave. Ste S")
  );
  doc.text("5015 W Edinger Ave. Ste S", xCenter + 20, 14);
  xCenter = getPositionCenter(105, doc.getTextWidth("Santa Ana. CA 92704"));
  doc.text("Santa Ana. CA 92704", xCenter + 20, 18);

  xCenter = getPositionCenter(105, doc.getTextWidth("Tel: (657) 456 3892"));
  doc.text("Tel: (657) 456 3892", xCenter + 20, 22);
  xCenter = getPositionCenter(
    105,
    doc.getTextWidth("nhatrangexpress.usa@gmail.com")
  );
  doc.text("nhatrangexpress.usa@gmail.com", xCenter + 20, 26);
  xCenter = getPositionCenter(
    105,
    doc.getTextWidth("https://nhatrangexpressusa.com")
  );
  doc.text("https://nhatrangexpressusa.com", xCenter + 20, 30);
  if (showTRK) {
    doc.setFontSize(10);
    xCenter = getPositionCenter(
      105,
      doc.getTextWidth(`TRK#: ${detail.trackingCode}`)
    );
    doc.text(`TRK#: ${detail.trackingCode}`, xCenter + 20, 35);
  }
};

export const renderMultipleShippingReceipt = async (orders?: Order[]) => {
  if (!orders || orders?.length == 0) return;
  var doc = new jsPDF("p", "mm", [105, 148]);

  for (let i = 0; i < orders.length; i++) {
    const order = orders?.[i];

    //@ts-ignore
    doc = await renderShippingReceiptPDF(order, doc);
    if (i < orders.length - 1) {
      doc.addPage();
    }
  }

  return doc;
};

const renderQRCode = async (trackingCode: string) => {
  const link = generateTrkLink({ trackingCode } as Order);

  //@ts-ignore
  await new QRCode(`qr_code`, {
    text: link,
    width: 128,
    height: 128,
    colorDark: "#000000",
    colorLight: "#ffffff",
    //@ts-ignore
    correctLevel: QRCode.CorrectLevel.H,
  });

  return new Promise((resolve) =>
    setTimeout(() => {
      const trackingIMG = document.querySelector(
        `#qr_code img`
      ) as HTMLImageElement;

      let base64Image = trackingIMG.src;

      resolve(base64Image);
      return base64Image;
    }, 100)
  );
};

export const renderMultipleShippingForm = async (orders?: Order[]) => {
  if (!orders || orders?.length == 0) return;
  var doc = new jsPDF("p", "mm", [105, 148]);

  for (let i = 0; i < orders.length; i++) {
    const order = orders?.[i];

    //@ts-ignore
    doc = await renderShippingFormPDF(order, doc);
    if (i < orders.length - 1) {
      doc.addPage();
    }
  }

  return doc;
};

export const acronymAddress = (address: string) => {
  return address
    .replaceAll("Phường" || "phường", "P.")
    .replaceAll("Quận" || "quận", "Q.")
    .replaceAll("Thành phố" || "thành phố" || "Thành Phố", "TP.");
};
