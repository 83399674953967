import { DownOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { IoEgg } from "react-icons/io5";
import {
  FeedbackStatus,
  feedbackStatusTrans,
  FeedbackType,
  feedbackTypeTrans,
} from "types/feedback";
import { FlyTripStatus, flyTripTrans } from "types/flyTrip";
import {
  OrderStatus,
  orderStatusTrans,
  PaymentType,
  PaymentTypeTrans,
} from "types/order";
import {
  ProductPackageType,
  productPackageTypeTrans,
} from "types/productPackage";

export const FeedbackTypeTag = ({ type }: { type: FeedbackType }) => {
  return (
    <Tag color={feedbackTypeTrans[type]?.color} style={{ fontSize: "11px" }}>
      {feedbackTypeTrans[type]?.title || type}
    </Tag>
  );
};

export const FeedbackStatusTag = ({
  status,
  arrow = false,
}: {
  status: FeedbackStatus;
  arrow?: boolean;
}) => {
  if (!status) return <></>;
  return (
    <Tag color={feedbackStatusTrans[status]?.color} style={{ marginRight: 0 }}>
      <Space size={5}>
        {feedbackStatusTrans[status]?.title || status}
        {arrow && <DownOutlined style={{ fontSize: "10px" }} />}
      </Space>
    </Tag>
  );
};

export const OrderStatusTag = ({ status }: { status: OrderStatus }) => {
  return (
    <Tag color={orderStatusTrans[status]?.color} style={{ marginRight: 0 }}>
      {orderStatusTrans[status]?.title || status}
    </Tag>
  );
};

export const FlyTripStatusTag = ({ status }: { status: FlyTripStatus }) => {
  return (
    <Tag color={flyTripTrans[status]?.color} style={{ marginRight: 0 }}>
      {flyTripTrans[status]?.title || status}
    </Tag>
  );
};

export const ProductPackageTypeTag = ({
  status,
}: {
  status: ProductPackageType;
}) => {
  return (
    <Tag
      color={productPackageTypeTrans[status]?.color}
      style={{ marginRight: 0, fontSize: 10 }}
    >
      {productPackageTypeTrans[status]?.label || status}
    </Tag>
  );
};

export const PaymentTypeTag = ({ type }: { type: string }) => {
  return (
    <Tag color={PaymentTypeTrans[type]?.color}>
      {PaymentTypeTrans[type]?.title || type}
    </Tag>
  );
};
