import { addressApi } from "api/address.api";
import { AddressParam } from "components/AddressSelect/AddressSelect";
import { useState } from "react";
import { City, District, Ward } from "types/address";

export const useAddress = () => {
  const [loading, setLoading] = useState(false);

  const [wards, setWards] = useState<Ward[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  const fetchWard = async (query: AddressParam) => {
    setLoading(true);
    const res = await addressApi.getWard(query);
    setLoading(false);
    setWards(res.data.wards);
  };
  const fetchDistrict = async (query: AddressParam) => {
    setLoading(true);
    const res = await addressApi.getDistricts(query);
    setLoading(false);
    setDistricts(res.data.districts);
  };
  const fetchCity = async (query?: AddressParam) => {
    setLoading(true);
    const res = await addressApi.getCities(query);
    setLoading(false);
    setCities(res.data.cities);
  };

  const clearDistrict = () => {
    setDistricts([]);
  };
  const clearWard = () => {
    setWards([]);
  };

  const updateCity = (data: City[]) => {
    setCities(data);
  };

  return {
    wards,
    districts,
    cities,
    loading,
    clearDistrict,
    clearWard,
    fetchWard,
    fetchDistrict,
    fetchCity,
    updateCity,
    setWards,
    setDistricts,
    setCities,
  };
};
