import moment from "moment";

export const unixToDate = (unixValue: number) => {
  const dateFormat = moment.unix(unixValue).format("MM/DD/YYYY");
  return dateFormat;
};

export const unixToTime = (unixValue: number) => {
  const dateFormat = moment.unix(unixValue).format("hh:mm A");
  return dateFormat;
};
export const unixToFullDate = (unixValue: number) => {
  if (unixValue > 0) {
    const dateFormat = moment.unix(unixValue).format("MM/DD/YYYY HH:mm");
    return dateFormat;
  }
  return "--";
};
