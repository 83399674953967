import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderForm } from "types/order";
import { getTitle } from "utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { relative } from "path";

const banners = [
  { id: 1, imageUrl: "/images/bg-1802.png" },
  { id: 2, imageUrl: "/images/banner-2.png" },
];
export const CheckTrackingInputSection = ({
  title,
  loading,
  onTracking,
}: {
  title: string;
  loading: boolean;
  onTracking: (trackingId: string) => void;
}) => {
  const [form] = useForm<Partial<OrderForm>>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.title = getTitle(title);
  }, [title]);

  useEffect(() => {
    if (searchParams.get("trackingId")) {
      handleAutoTracking(searchParams.get("trackingId") as string);
    }
  }, [searchParams.get("trackingId")]);

  const handleAutoTracking = (trackingParam?: string) => {
    const trackingId = form.getFieldValue("trackingId");
    if (trackingParam && !trackingId) {
      form.setFieldValue("trackingId", trackingParam);

      handleCheck();
    }
  };

  const handleCheck = () => {
    const trackingId = form.getFieldValue("trackingId");

    if (trackingId) {
      const trackingIdsArray = trackingId
        .split(",")
        .map((id) => id.trim())
        .filter((id) => id);

      const trackingIds = trackingIdsArray.join(",");
      onTracking(trackingIds);
    } else {
      message.warning("Vui lòng nhập Tracking ID");
    }
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <Swiper
          autoplay={{ delay: 4000 }}
          loop
          speed={2000}
          modules={[Autoplay]}
          style={{ width: "100%", height: "100%" }}
        >
          {banners.map((banner) => (
            <SwiperSlide key={banner.id}>
              <div
                className="b-container banner"
                style={{
                  backgroundImage: `url('${banner.imageUrl}')`,
                  height: "100%",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Position the form absolutely on top of the Swiper */}
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2, // Ensure form is above the banner
            width: "100%",
            maxWidth: "370px",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Row justify="center">
            <Col span={24}>
              <Form
                layout="horizontal"
                form={form}
                className="tracking-form"
                style={{ display: "flex" }}
              >
                <FormItem name="trackingId" style={{ flex: 1 }}>
                  <Input
                    placeholder="Tìm đơn hàng theo số TRK# phân cách bởi dấu `,`"
                    onPressEnter={handleCheck}
                  />
                </FormItem>
                <FormItem>
                  <Button
                    onClick={handleCheck}
                    type="primary"
                    size="large"
                    loading={loading}
                    icon={<SearchOutlined />}
                    style={{ width: "100%", maxWidth: "370px" }}
                  >
                    Tra cứu / Track
                  </Button>
                </FormItem>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export { CheckTrackingInputSection as default };
