import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Descriptions,
  Drawer,
  Menu,
  MenuProps,
  Modal,
  Result,
  Space,
} from "antd";
import { useWindowSize } from "hooks/useWindowSize";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AiFillBook } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Route } from "router";
import { settings } from "setting";
import { permissionStore } from "store/permissionStore";
import { userStore } from "store/userStore";
import { docSoBangChu } from "utils/docTienBangChu";
import { getUserName } from "utils/getUserName";

const generateAccessRouter = (routers: any[]) => {
  const accessRoutes = routers.filter((route) => {
    return (route.isAccess || !settings.checkPermission) && !route.isHidden;
  });

  return accessRoutes.map((router: Route) => {
    if (router.alwayShow || !router.children) {
      let url = "";
      if (router.children) url = router.path + "/" + router.children[0].path;
      else url = router.path as string;

      return {
        label: (
          <Link
            className="parent-menu"
            to={url}
            key={router.name}
            style={{
              padding: "10px 0",
              display: "inline-block",
            }}
          >
            <Space size={10}>
              {router.icon}
              {router.title}
            </Space>
          </Link>
        ),
        key: router.name,
        // icon: router.icon,
      };
    } else {
      return {
        label: (
          <Space size={2}>
            {router.title} <DownOutlined style={{ fontSize: "10px" }} />
          </Space>
        ),
        key: router.name,
        icon: router.icon,
        children: generateChildRouter(router.children, router.path as string),
      };
    }
  });
};

const generateChildRouter = (childrenRouter: any[], parentPath: string) => {
  return childrenRouter
    .filter((e) => (e.isAccess || !settings.checkPermission) && !e.isHidden)
    .map((router: Route) => {
      const url = parentPath + "/" + router.path;

      if (router.name == "logout") {
        return {
          type: "group",
          label: (
            <div
              onClick={() => {
                userStore?.logout();
                window.location.href = "/login";
              }}
              style={{
                color: "red",
                padding: "10px",
                paddingLeft: "15px",
                display: "block",
                cursor: "pointer",
              }}
              className="submenu"
            >
              <Space size={5}>
                {router.icon} {router.title}
              </Space>
            </div>
          ),
        };
      } else {
        return {
          type: "group",

          label: (
            <Link
              style={{
                color: "#000",
                padding: "10px",
                paddingLeft: "15px",
                display: "block",
              }}
              to={url}
              className="submenu"
            >
              <Space size={5}>
                {router.icon} {router.title}
              </Space>
            </Link>
          ),
        };
      }
    });
};

const TopNavbar: React.FC = () => {
  const [current, setCurrent] = useState("depot");
  const [menu, setMenu] = useState([]);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [visibleGuide, setVisibleGuide] = useState(false);

  const accessRoutes = permissionStore.accessRoutes;

  // console.log("toJS accessRoutes", toJS(accessRoutes));

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    const menuData = generateAccessRouter(accessRoutes);
    const guideRoute = {
      label: (
        <div
          onClick={() => {
            setVisibleGuide(true);
            console.log("visible guide");
          }}
          className="parent-menu"
          style={{
            padding: "10px 0",
            display: "inline-block",
          }}
        >
          <Space size={10}>
            <AiFillBook
              fontSize={15}
              style={{ transform: "translateY(2px)" }}
            />
            Hướng dẫn gửi hàng
          </Space>
        </div>
      ),
      key: "hdsd",
      // icon: router.icon,
    };
    menuData.push(guideRoute);
    //@ts-ignore
    setMenu(menuData);
  }, []);

  useEffect(() => {
    const currentKey = location.pathname.split("/");
    const basePath = currentKey[0] || currentKey[1];

    setCurrent(basePath);
  }, [location]);

  return (
    <>
      {!!width && width >= 800 && (
        <div>
          <Menu
            selectedKeys={[current]}
            activeKey={current}
            className="custom-menu"
            triggerSubMenuAction="hover"
            theme="light"
            onClick={onClick}
            // selectedKeys={[current]}
            mode="horizontal"
            items={menu}
            style={{
              padding: "0",
              borderBottom: "none",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabledOverflow
          />
        </div>
      )}

      {userStore?.token && !!width && width <= 800 && (
        <>
          <MenuFoldOutlined
            onClick={() => setVisibleMenu(true)}
            style={{
              position: "absolute",
              right: "20px",
              top: "28px",
              fontSize: "18px",
            }}
          />
          <Drawer
            width={"250px"}
            bodyStyle={{ padding: 0 }}
            headerStyle={{
              height: "20px",
              padding: "15px 5px",
              border: "none",
            }}
            placement="right"
            onClose={() => setVisibleMenu(false)}
            open={visibleMenu}
          >
            <Menu
              onSelect={() => setVisibleMenu(false)}
              selectedKeys={[current]}
              activeKey={current}
              className="custom-menu vertical"
              triggerSubMenuAction="hover"
              theme="light"
              onClick={onClick}
              // itemIcon={<></>}
              expandIcon={<></>}
              // selectedKeys={[current]}
              mode="vertical"
              items={menu}
              style={{
                padding: "0",
                borderBottom: "none",
                width: "100%",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
              disabledOverflow
            />
            <div
              style={{
                marginTop: "auto",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
              }}
            >
              {userStore?.token ? (
                <Space
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    padding: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Space size={5}>
                    <Avatar icon={<UserOutlined />} />
                    {userStore?.info.name}
                  </Space>
                  <LogoutOutlined
                    onClick={() => {
                      userStore?.logout();
                      navigate("/");
                    }}
                  />
                </Space>
              ) : (
                <Menu
                  onSelect={() => setVisibleMenu(false)}
                  className="custom-menu vertical"
                  // selectedKeys={[current]}
                  mode="vertical"
                  //@ts-ignore
                  items={[
                    {
                      label: (
                        <Link
                          className="parent-menu"
                          to={"/sign-up"}
                          style={{
                            padding: "10px 0",
                            display: "inline-block",
                          }}
                        >
                          <Space className="text-blue" size={10}>
                            Đăng ký
                          </Space>
                        </Link>
                      ),
                      key: 1,
                    },
                    {
                      label: (
                        <Link
                          className="parent-menu"
                          to={"/login"}
                          style={{
                            padding: "10px 0",
                            display: "inline-block",
                          }}
                        >
                          <Space size={10}>Đăng nhập</Space>
                        </Link>
                      ),
                      key: 2,
                    },
                  ]}
                  style={{
                    padding: "0",
                    borderBottom: "none",
                    width: "100%",
                  }}
                  disabledOverflow
                />
              )}
            </div>
          </Drawer>
        </>
      )}
      <Modal
        open={visibleGuide}
        footer={null}
        onCancel={() => setVisibleGuide(false)}
      >
        <Result
          className="login-result"
          style={{ paddingBottom: "1em", paddingTop: "1em" }}
          status="info"
          title="Hướng dẫn gửi hàng"
          subTitle={
            <div
              style={{
                textAlign: "left",
                color: "#000",
                marginTop: "10px",
              }}
            >
              <ul>
                <li>
                  <b> Customer ID:</b> {userStore?.info?.code}
                </li>

                <li>
                  <b>Thông tin người gửi:</b>
                  <ul>
                    <li>
                      <b>Họ tên:</b> {userStore?.info?.name}
                    </li>
                  </ul>
                </li>
              </ul>

              <Alert
                type="warning"
                description={
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      Quý khách có thể gửi hàng về kho US theo cấu trúc sau:
                      <br />
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <b>First name:</b>{" "}
                      {docSoBangChu(userStore?.info?.code || "")}{" "}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <b>Last name:</b>{" "}
                      {getUserName(userStore?.info?.name || "")}
                    </p>
                    <Descriptions>
                      <Descriptions.Item
                        style={{ padding: 0 }}
                        label="Address"
                        contentStyle={{ flexDirection: "column" }}
                        labelStyle={{ fontWeight: "bold" }}
                      >
                        <p style={{ marginBottom: 0 }}>
                          5015 W EDINGER AVE, STE S
                        </p>

                        <p style={{ marginBottom: 0 }}>SANTA ANA, CA 92704</p>

                        <p style={{ marginBottom: 0 }}>657 456 3892</p>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                }
              />

              <p className="text-center" style={{ marginTop: "10px" }}>
                <i>
                  Nếu quý khách cần hỗ trợ xin liên lạc NHA TRANG EXPRESS:{" "}
                  <br />
                  Phone/Viber/Zalo: 657 456 3892
                </i>
              </p>
            </div>
          }
        />
      </Modal>
    </>
  );
};

export default observer(TopNavbar);
