import { request } from "utils/request";
import { AxiosPromise } from "axios";

export const addressApi = {
  getDistricts: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/district",
      params,
    }),
  getCities: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/city",
      params,
    }),
  getWard: (params?: any): AxiosPromise<any> =>
    request({
      url: "/v1/customer/ward",
      params,
    }),

  updateShipFee: (id: number, data: any): AxiosPromise<any> =>
    request({
      url: `/v1/customer/city/${id}`,
      method: "patch",
      data,
    }),
};
