import { settings } from "setting";
import { Order } from "types/order";

export const generateTrkLink = (orderDetail?: Order) => {
  if (!orderDetail) return;

  const trackingQr = `${settings.trackingLink}?trackingId=${orderDetail?.trackingCode}`;

  return trackingQr;
};
