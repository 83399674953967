export const getTitle = (title: string) => {
  return title + " - " + process.env.REACT_APP_WEBSITE_NAME;
};

export function formatVND(number: number) {
  // return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }); /* $2,500.00 */
}

export function formatNumber(number: number) {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function formatNumberEn(number: number) {
  return new Intl.NumberFormat("de-DE").format(number);
}

export function formatMilion(number: number) {
  if (number < 1000000) {
    return formatVND(number);
  } else {
    return formatVND(Number((number / 1000000).toFixed(1))) + " triệu";
  }
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
