import { Modal } from "antd";
import { GoogleMaps } from "components/Map/GoogleMaps";
import { useState } from "react";
import { CoordAddress } from "../GoogleMapAutoComplete";
// import "../styles/addressModal.scss";

export const AddressModal = ({
  open,
  onClose,
  onSubmitOk,
}: {
  open: boolean;
  onClose: () => void;
  onSubmitOk: (
    address: string,
    lat: number,
    long: number,
    city: string,
    state: string,
    zipCode: string,
    street: string
  ) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState<CoordAddress>();

  const handleSubmitForm = async () => {
    if (addressInfo) {
      onSubmitOk(
        addressInfo.address,
        addressInfo.lat,
        addressInfo.lng,
        addressInfo.city || "",
        addressInfo.state || "",
        addressInfo.zipCode || "",
        addressInfo.street || ""
      );
      onClose();
    }
  };

  return (
    <Modal
      className="address-modal"
      onCancel={onClose}
      open={open}
      title={"Chọn địa chỉ"}
      confirmLoading={loading}
      onOk={handleSubmitForm}
      width={600}
    >
      <GoogleMaps onPlaceSelected={(address) => setAddressInfo(address)} />
    </Modal>
  );
};
