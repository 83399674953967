import { Customer } from "./customer";
import { QueryParam } from "./query";
import { QueryObject } from "./queryObject";
import { Staff } from "./staff";

export interface Feedback {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  code: string;
  title: string;
  type: FeedbackType;
  status: FeedbackStatus;
  privateNote: string; //ghi chú nội bộ
  customer: Customer;
  feedbackLogs: FeedbackLog[];
}

export interface FeedbackLog {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  note: string;
  status: FeedbackStatus;
  feedback: Feedback;
  staff: Staff; //staff chuyển trạng thái
}
export interface FeedbackQuery extends QueryParam {
  type?: FeedbackType;
  status?: FeedbackStatus;
  fromDate?: string;
  toDate?: string;
  customerId?: number;
  queryObject?: string;
}

export enum FeedbackStatus {
  Open = "OPEN",
  InProgress = "IN_PROGRESS", //
  Waiting = "WAITING",
  Closed = "CLOSED",
  ReOpen = "REOPEN",
}

export enum FeedbackType {
  RequestOrder = "REQUEST_ORDER", //yêu cầu tạo đơn
  Support = "SUPPORT", //yêu cầu hỗ trợ
}

export const feedbackStatusTrans = {
  [FeedbackStatus.Open]: {
    title: "Open",
    color: "cyan",
    value: FeedbackStatus.Open,
  },
  [FeedbackStatus.InProgress]: {
    title: "In progress",
    color: "geekblue",
    value: FeedbackStatus.InProgress,
  },
  [FeedbackStatus.Waiting]: {
    title: "Waiting",
    color: "blue",
    value: FeedbackStatus.Waiting,
  },
  [FeedbackStatus.Closed]: {
    title: "Closed",
    color: "red",
    value: FeedbackStatus.Closed,
  },
  [FeedbackStatus.ReOpen]: {
    title: "Re-open",
    color: "cyan",
    value: FeedbackStatus.ReOpen,
  },
};

export const feedbackTypeTrans = {
  [FeedbackType.RequestOrder]: {
    title: "Yêu cầu tạo đơn",
    color: "purple",
    value: FeedbackType.RequestOrder,
  },
  [FeedbackType.Support]: {
    title: "Hỗ trợ",
    color: "geekblue",
    value: FeedbackType.Support,
  },
};

export interface FeedbackLog {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  note: string;
  status: FeedbackStatus;
  feedback: Feedback;
  staff: Staff; //staff chuyển trạng thái
}
