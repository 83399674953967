import { Badge, Button, Dropdown, List, Menu, message } from "antd";
import { useEffect, useState } from "react";

// import { notificationApi } from "api/notification.api";
import { IoIosNotificationsOutline } from "react-icons/io";
// import { INotification } from "types/notification";
import { QueryParam } from "types/query";
import AppScrollbar from "../AppScrollbar";
import "./index.style.less";
import NotificationItem from "./NotificationItem";
import { observer } from "mobx-react";
import { userStore } from "store/userStore";

const AppNotifications = observer(() => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState<QueryParam>({ page: 1, limit: 10 });
  const [notifications, setNotifications] = useState<any[]>();
  useEffect(() => {}, []);

  const fetchNotificaition = async () => {
    setLoading(true);
    // const res = await notificationApi.findAll(query);
    setLoading(false);
    // setNotifications(res.data.notifications);
  };

  useEffect(() => {
    fetchNotificaition();
  }, []);

  const readAll = async () => {
    setLoading(true);
    // await notificationApi.readAll();
    message.success("Đánh dấu tất cả là đã đọc");
    setLoading(false);
    userStore.getProfile();
    fetchNotificaition();
  };

  const menu = (
    <Menu className="notify-header-message">
      <Menu.Item className="header">Thông báo</Menu.Item>
      <Menu.Item>
        {/*
 // @ts-ignore */}
        <AppScrollbar className="notify-scroll-submenu">
          <List
            className="notify-list"
            dataSource={notifications}
            renderItem={(item) => {
              return (
                <NotificationItem
                  key={item.id}
                  item={item}
                  onReadOK={fetchNotificaition}
                />
              );
            }}
          />
        </AppScrollbar>
      </Menu.Item>
      {!!notifications?.length && (
        <Menu.Item>
          <Button
            type="primary"
            className="notify-btn-all"
            style={{ textTransform: "unset" }}
            onClick={readAll}
          >
            Đánh dấu tất cả là đã đọc
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      className="dropdown"
      trigger={["click"]}
      onVisibleChange={(visible) => {
        if (visible) fetchNotificaition();
      }}
    >
      <Badge
        offset={[-8, 8]}
        count={
          userStore.info?.numOfNotifications &&
          userStore.info?.numOfNotifications > 0
            ? userStore.info.numOfNotifications
            : ""
        }
      >
        <a className="notify-link" onClick={(e) => e.preventDefault()}>
          <span className="notify-icon">
            <IoIosNotificationsOutline />
          </span>
          <span className="notify-text">Xem tất cả</span>
        </a>
      </Badge>
    </Dropdown>
  );
});

export default AppNotifications;
