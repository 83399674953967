import { toJS } from "mobx";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { settings } from "setting";

import { permissionStore } from "store/permissionStore";
import { userStore } from "store/userStore";
import { getToken } from "utils/auth";
import { useBasePath } from "./useBasePath";

const whileList = [
  "/login",
  "/sign-up",
  "/order-tracking",
  "/tos",
  "/services",
];

export const useRouter = (isLoaded: boolean) => {
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = useBasePath();

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const token = getToken();

    if (!token) {
      if (whileList.includes(location.pathname)) {
        return;
      }
      userStore.logout();
      return navigate("/order-tracking");
    }

    if (location.pathname == "/login" && token) {
      return navigate("/order-tracking");
    }

    if (whileList.includes(location.pathname)) {
      return;
    }

    // if (permissionStore.accessRoutes.length) {
    if (location.pathname == "/") {
      if (token) {
        let firstRoute = permissionStore.accessRoutes.find(
          (e) => e.isAccess || !settings.checkPermission
        );
        const route = firstRoute?.children
          ? firstRoute.path + "/" + firstRoute.children[0].path
          : firstRoute?.path;

        navigate(route || "/order-tracking");
      } else {
        navigate("/order-tracking");
      }
    } else {
      // console.log("toJS", toJS(permissionStore.permissions));
      //

      if (
        settings.checkPermission &&
        !permissionStore.permissions.some(
          (p) => p.path.indexOf(basePath as string) > -1
        )
      ) {
        console.log("permissions", toJS(permissionStore.permissions));
        navigate("/404");
      }
    }
    // }

    handleScrollToTop();
  }, [location.pathname, isLoaded, basePath]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return location;
};
